import React, { useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";

import { Filter } from "@trace-one/business-components";
import { Input, Divider, Picker, FilterProps } from "@trace-one/design-system";
import { Label } from "@trace-one/react-components";
import { Form } from "antd";

import { GroupCompaniesFilter } from "../../../../pages/Groups/models";
import { GroupModalMode } from "../../../../shared/constants";
import { adjustIntercomButton } from "../../../../shared/utils/toggleIntercom";
import { SearchFiltersProps } from "../../../SearchFilters";
import styles from "../../GroupModal.module.less";

export interface GroupSearchProps {
  mode?: string;
  sortedCountriesOptions?: { value: string; label: string }[];
  setGroupName?: (e) => void;
  setGroupCode?: (e) => void;
  filterObj?: GroupCompaniesFilter;
  mergeFilters?: (
    value:
      | Partial<GroupCompaniesFilter>
      | ((prevState: GroupCompaniesFilter) => GroupCompaniesFilter)
  ) => void;
  initialSearchValue?: string;
  onSearch?: SearchFiltersProps["onSearch"];
  onClearFiltersClick?: SearchFiltersProps["onClearFiltersClick"];
  companyActivities?: any;
  groupNameError?: boolean;
  setGroupNameError?: (boolean) => void;
  existingGroupName?: string;
  existingGroupCode?: string;
}
const GroupSearch: React.FC<GroupSearchProps> = ({
  mode,
  sortedCountriesOptions,
  setGroupName,
  setGroupCode,
  filterObj,
  mergeFilters,
  initialSearchValue,
  onSearch,
  onClearFiltersClick,
  companyActivities,
  groupNameError,
  setGroupNameError,
  existingGroupName,
  existingGroupCode,
}) => {
  const showIntercom = useRef(true);

  const { formatMessage } = useIntl();

  const [selectedFilters, setSelectedFilters] = useState<
    FilterProps["selectedFilters"]
  >([]);

  const companiesActivitiesOptions = companyActivities?.map(
    ({ itemCode, text }) => ({
      value: itemCode,
      label: text,
    })
  );

  useEffect(() => {
    const { companyActivityId, companyCountry } = filterObj;

    const defaultSelectedFilters = [
      companyActivityId && {
        filterId: "1",
        values: [filterObj?.companyActivityId],
      },

      companyCountry && {
        filterId: "2",
        values: [companyCountry],
      },
    ].filter(Boolean);

    setSelectedFilters(defaultSelectedFilters);
  }, [filterObj]);

  const filters = [
    {
      title: formatMessage({ id: "general.companyType" }),
      controlType: "single_selection",
      clearSelection: () => {
        mergeFilters({ companyActivityId: undefined });
      },
      elementToRender: null,
      quickFilter: (
        <Picker.Filter
          title={formatMessage({ id: "general.companyType" })}
          showSearchInput
          selectionType="single"
          value={
            filterObj.companyActivityId ? [filterObj.companyActivityId] : []
          }
          items={companiesActivitiesOptions}
          onChange={({ value }) => {
            const selectedId =
              value[0] === filterObj.companyActivityId ? undefined : value[0];
            mergeFilters({
              companyActivityId: selectedId,
            });
          }}
          clearSelection={() =>
            mergeFilters({
              companyActivityId: undefined,
            })
          }
          data-test-id="group-companies-filter-picker-companyType"
        />
      ),
      filterId: "1",
    },

    {
      title: formatMessage({ id: "general.country" }),
      controlType: "single_selection",
      clearSelection: () => {
        mergeFilters({ companyCountry: undefined });
      },
      elementToRender: null,
      quickFilter: (
        <Picker.Filter
          title={formatMessage({ id: "general.country" })}
          showSearchInput
          selectionType="single"
          value={filterObj.companyCountry ? [filterObj.companyCountry] : []}
          items={sortedCountriesOptions && sortedCountriesOptions}
          onChange={({ value }) => {
            const selectedCountry =
              value[0] === filterObj.companyCountry ? undefined : value[0];
            mergeFilters({
              companyCountry: selectedCountry,
            });
          }}
          clearSelection={() =>
            mergeFilters({
              companyCountry: undefined,
            })
          }
          data-test-id="group-companies-filter-picker-country"
        />
      ),
      filterId: "2",
    },
  ];

  return (
    <div data-test-id="group-modal-search">
      <div className={styles.groupInfo}>
        <Label
          title={formatMessage({ id: "groupList.table.groupName" })}
          required
        >
          <Form.Item name="groupName">
            <Input
              placeholder={formatMessage({
                id: "general.enterValue",
              })}
              onChange={e => {
                setGroupName(e.target.value);
                setGroupNameError(false);
              }}
              disabled={mode === GroupModalMode.ADD_TO_GROUP}
              maxLength={186}
              className={groupNameError ? "input-error" : ""}
              defaultValue={existingGroupName}
            />
          </Form.Item>
        </Label>

        <Label title={formatMessage({ id: "groupList.table.groupCode" })}>
          <Form.Item name="groupCode">
            <Input
              placeholder={formatMessage({
                id: "general.enterValue",
              })}
              onChange={e => {
                setGroupCode(e.target.value);
              }}
              disabled={mode === GroupModalMode.ADD_TO_GROUP}
              maxLength={36}
              defaultValue={existingGroupCode}
            />
          </Form.Item>
        </Label>
      </div>

      <Divider color="grey-3" dashed />

      <Filter
        data-test-id="group-modal-filter"
        filters={filters}
        selectedFilters={selectedFilters}
        clearAllFilters={onClearFiltersClick}
        hideAllFilters
        search={{
          placeholder: formatMessage({ id: "general.enterCompanyName" }),
          defaultValue: initialSearchValue,
          onSearch: query => onSearch(query),
        }}
        onAllFilterPanelToggle={() => {
          showIntercom.current = !showIntercom.current;
          adjustIntercomButton(showIntercom.current);
        }}
      />
    </div>
  );
};

export default GroupSearch;
