import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useIntl } from "react-intl";

import { GroupDetails } from "@trace-one/api-clients.cumd/dist/models/group";
import { Select, Heading, Typography } from "@trace-one/design-system";

import LeavingModal from "../components/LeavingModal";

import styles from "./CompanySelectSection.module.less";
interface CompanySelectSectionProps {
  groupDetails?: GroupDetails;
  currentCompanyId?: string;
  setSelectedCompanyId?: any;
  setSelectedCompanyName?: any;
  isFormDirty?: boolean;
  setIsFormDirty?: any;
  setError?: any;
  user?: any;
  foundSubscriptionId?: string;
  roleIds?: any;
  setSelectedApp: Dispatch<SetStateAction<number>>;
}
const CompanySelectSection: React.FC<CompanySelectSectionProps> = ({
  groupDetails,
  currentCompanyId,
  setSelectedCompanyId,
  setSelectedCompanyName,
  isFormDirty,
  setIsFormDirty,
  setError,
  user,
  foundSubscriptionId,
  roleIds,
  setSelectedApp,
}) => {
  const { formatMessage } = useIntl();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [pendingCompanyId, setPendingCompanyId] = useState<string | null>(null);
  const [pendingCompanyName, setPendingCompanyName] = useState<string | null>(
    null
  );
  const [selectedCompanyIdInternal, setSelectedCompanyIdInternal] = useState<
    string | null
  >();

  const [companyOptions, setCompanyOptions] = useState([]);

  useEffect(() => {
    if (groupDetails?.companies && groupDetails?.companies.length > 0) {
      const filteredCompanies = groupDetails?.companies
        .filter(
          company => company.isActive && company.companyId !== currentCompanyId
        )
        .map(company => ({
          label: company.companyDisplayName,
          value: company.companyId,
        }));
      setCompanyOptions(filteredCompanies);
    }
  }, [groupDetails?.companies, currentCompanyId]);

  const changeCompany = () => {
    if (pendingCompanyId && pendingCompanyName) {
      setSelectedCompanyIdInternal(pendingCompanyId);
      setSelectedCompanyId(pendingCompanyId);
      setSelectedCompanyName(pendingCompanyName);
      setPendingCompanyId(null);
      setPendingCompanyName(null);
    }
  };

  return (
    <div
      className={styles.container}
      data-test-id="ad-user-manage-group-access-company-selection"
    >
      <Heading size="s">{formatMessage({ id: "general.company" })}</Heading>
      <Typography>
        {formatMessage({
          id: "userAccessManage.groupAccess.companyDescription",
        })}
      </Typography>
      <div className={styles.selectWrapper}>
        <Select
          showSearch
          allowClear
          placeholder={formatMessage({ id: "general.selectCompany" })}
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          options={companyOptions}
          onChange={async (value: string, name: any) => {
            if (isFormDirty) {
              setPendingCompanyId(value);
              setPendingCompanyName(name);
              setIsModalOpen(true);
            } else {
              setSelectedCompanyIdInternal(value);
              setSelectedCompanyId(value);
              setSelectedCompanyName(name);
            }
            setSelectedApp(null);
          }}
          value={selectedCompanyIdInternal}
          label={formatMessage({ id: "general.companyGroup" })}
          data-test-id="ad-user-manage-group-access-company-selection-select"
        />
      </div>

      <LeavingModal
        useFor="CompanySelect"
        visible={isModalOpen}
        setVisible={setIsModalOpen}
        changeCompany={changeCompany}
        setIsFormDirty={setIsFormDirty}
        setError={setError}
        user={user}
        foundSubscriptionId={foundSubscriptionId}
        roleIds={roleIds}
      />
    </div>
  );
};

export default CompanySelectSection;
