import { useEffect, useState, useCallback } from "react";
import { useIntl } from "react-intl";

import { Graphic } from "@trace-one/design-system";

import { CumdAPI } from "apis";
import { GroupData } from "models";

import IconExpand from "components/IconExpand";
import Spinner from "components/Spinner";
import Table from "components/Table";
import { APPLICATIONS } from "shared/constants";
import useToast from "shared/hooks/useToast";

import { companyGroupDetails } from "../../../models";
import { GroupCompaniesColumn } from "../../../models";
import GroupTableHeader from "../../GroupList/GroupTableHeader";
import useDetailsTable from "../hooks/useDetailsTable";

import styles from "./GeneralInformation.module.less";
import GroupBannerDetails from "./GroupBannerDetails";

interface ExpandedRowsProps {
  record: GroupCompaniesColumn;
}

const GeneralInformation = ({ groupDetails }: { groupDetails: GroupData }) => {
  const toast = useToast();
  const [companies, setCompanies] = useState<companyGroupDetails[]>([]);
  const [isLoading, setIsLoading] = useState<Boolean>(false);
  const [companyGroupId, setCompanyGroupId] = useState<string>();
  const [mainCompanyId, setMainCompanyId] = useState<string>();

  const fetchGroupdata = useCallback(async () => {
    let totalCompaniesNumber = groupDetails?.companies?.length;
    try {
      setIsLoading(true);
      let data = [];
      let take = 1;
      while (totalCompaniesNumber > 0) {
        const {
          data: { companies },
        } = await CumdAPI.getCompaniesByFiltersForToAdmin(
          {
            companyIds: groupDetails?.companies?.map(c => c.companyId),
          },
          {
            take: 100,
            skip: 100 * (take - 1),
          }
        );

        data = data.concat(companies);
        totalCompaniesNumber = totalCompaniesNumber - 100;
        take++;
      }

      const addedByIds = groupDetails?.companies
        .map(c => c.addedBy)
        .filter(Boolean);
      const mainContactIds = data.map(c => c.mainContactUserId).filter(Boolean);

      if (addedByIds?.length === 0 && mainContactIds.length === 0) {
        const companyGroupId = groupDetails?.companyGroupId;
        const mainCompanyId = groupDetails?.mainCompanyId;

        const companiesData = groupDetails?.companies?.map(company => {
          const {
            companyDisplayName,
            companyLegalName,
            companyDuns,
            userCount,
            mainContactUserId,
            applicationTypeIds,
          } = data.find(c => c.companyId === company.companyId) || {};

          const addedBy = usersData?.find(
            user => user.userId === company.addedBy
          );
          const mainContact = usersData?.find(
            user => user.userId === mainContactUserId
          );

          return {
            ...company,
            companyDisplayName,
            companyLegalName,
            companyDuns,
            userCount,
            applicationTypeIds: applicationTypeIds,
            addedBy: addedBy
              ? [addedBy?.userFirstName, addedBy?.userLastName]
                  .filter(item => !!item)
                  .join(" ")
              : "",
            mainContactUserId: mainContact?.userId,
            mainContactUserName: mainContact
              ? [mainContact?.userFirstName, mainContact?.userLastName]
                  .filter(item => !!item)
                  .join(" ")
              : "",
          };
        });
        setCompanies(companiesData);
        setCompanyGroupId(companyGroupId);
        setMainCompanyId(mainCompanyId);
        setIsLoading(false);
        return;
      }

      const { data: usersData } = await CumdAPI.getUsersByUserIds({
        userIds: addedByIds.concat(mainContactIds),
      });

      const companyGroupId = groupDetails?.companyGroupId;
      const mainCompanyId = groupDetails?.mainCompanyId;

      const companiesData = groupDetails?.companies?.map(company => {
        const {
          companyDisplayName,
          companyLegalName,
          companyDuns,
          userCount,
          mainContactUserId,
          applicationTypeIds,
        } = data.find(c => c.companyId === company.companyId) || {};

        const addedBy = usersData?.find(
          user => user.userId === company.addedBy
        );
        const mainContact = usersData?.find(
          user => user.userId === mainContactUserId
        );
        return {
          ...company,
          companyDisplayName,
          companyLegalName,
          companyDuns,
          userCount,
          applicationTypeIds: applicationTypeIds,
          addedBy: addedBy
            ? [addedBy?.userFirstName, addedBy?.userLastName]
                .filter(item => !!item)
                .join(" ")
            : "",
          mainContactUserId: mainContact?.userId || "",
          mainContactUserName: mainContact
            ? [mainContact.userFirstName, mainContact?.userLastName]
                .filter(item => !!item)
                .join(" ")
            : "",
        };
      });
      setCompanies(companiesData);
      setCompanyGroupId(companyGroupId);
      setMainCompanyId(mainCompanyId);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      toast.saveError({ error });
    }
  }, [groupDetails?.companyGroupId, groupDetails?.companies]);

  const CompanyInfoRows: React.FC<ExpandedRowsProps> = ({ record }) => {
    const { formatMessage } = useIntl();

    return (
      <div className={styles.expandedWrapper}>
        <ul className={styles.expandedList}>
          <li className={styles.titleRow}>
            <span className={styles.tableHead}>
              {formatMessage({
                id: "general.dunsName",
              })}
            </span>
            <span className={styles.tableHead}>
              {formatMessage({
                id: "general.dunsNumber",
              })}
            </span>
            <span style={{ width: "50%" }} className={styles.tableHead}>
              {formatMessage({
                id: "general.applications",
              })}
            </span>
          </li>
          <li key={record.key}>
            <span className={styles.tableContent}>{record.legalName}</span>
            <span className={styles.tableContent}>{record.dunsCode}</span>
            <span style={{ width: "50%" }} className={styles.tableContent}>
              {record?.applicationTypeIds?.map(id => {
                const app = APPLICATIONS.find(a => a.id === id);
                return (
                  <Graphic
                    name={`app-logo-${app?.graphic}-full`}
                    size="medium"
                  />
                );
              })}
            </span>
          </li>
        </ul>
      </div>
    );
  };

  useEffect(() => {
    if (!!groupDetails.companies.length) {
      fetchGroupdata();
    }
  }, [fetchGroupdata]);

  const { columns, data } = useDetailsTable({
    companies,
    mainCompanyId,
    companyGroupId,
  });

  const displayOnlyActiveCompanies = data?.filter(
    company => company?.isActive && data
  );

  const activeCompanies = companies.filter(company => company?.isActive);

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <div className={styles.root}>
      <GroupBannerDetails
        groupDetails={groupDetails}
        companies={companies}
      ></GroupBannerDetails>
      <GroupTableHeader currentNumber={activeCompanies.length} />
      <Table
        columns={columns}
        dataSource={displayOnlyActiveCompanies}
        expandable={{
          expandIcon: props => <IconExpand {...props} />,
          expandedRowRender: record => <CompanyInfoRows record={record} />,
        }}
        hidePagination
      />
    </div>
  );
};

export default GeneralInformation;
