import React from "react";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";

import { Button } from "@trace-one/design-system";
import { Col, Typography } from "antd";

import Card from "components/Card";

import usePermissions from "../../../../../core/oidc/usePermissions";
import { CompanyForTraceoneAdminData } from "../../../../../models";
import { CompanyStatus } from "../../../../../shared/constants";

import styles from "./DunsInformationCard.module.less";

interface DunsInformationCardProps {
  company: CompanyForTraceoneAdminData;
}

const DunsInformationCard: React.FC<DunsInformationCardProps> = ({
  company,
}) => {
  const { hasRoleAdministrator, hasRoleSuperAdmin, isTraceoneAdmin } =
    usePermissions();

  const { formatMessage } = useIntl();
  const history = useHistory();

  return (
    <div className={styles.root}>
      <Card
        title={formatMessage({ id: "companyGeneralInfo.dunsInfo.title" })}
        extra={
          (hasRoleSuperAdmin || hasRoleAdministrator) &&
          isTraceoneAdmin &&
          company?.companyStatus === CompanyStatus.ENABLED && (
            <Button
              type="secondary"
              data-test-id="company-update-duns-update-button"
            >
              <Typography.Link
                onClick={() => {
                  history.push(`/companies/${company.companyId}/changeDuns`);
                }}
              >
                {formatMessage({ id: "general.updateDuns" })}
              </Typography.Link>
            </Button>
          )
        }
      >
        <Col className={styles.info} style={{ padding: "0" }}>
          <span className={styles.infoTitle}>
            {formatMessage({ id: "general.dunsName" })}
          </span>
          <div>
            {company?.companyLegalName
              ? company?.companyLegalName
              : formatMessage({ id: "general.empty" })}
          </div>
        </Col>

        <Col className={styles.info} style={{ padding: "0" }}>
          <span className={styles.infoTitle}>
            {formatMessage({ id: "general.dunsNumber" })}
          </span>
          <div>
            {company?.companyDuns
              ? company?.companyDuns
              : formatMessage({ id: "general.empty" })}
          </div>
        </Col>
      </Card>
    </div>
  );
};

export default DunsInformationCard;
