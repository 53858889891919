import { defineMessages } from "react-intl";

export const allergensModalMessages = defineMessages({
  title: {
    id: "shared.business.allergensModal.title",
    defaultMessage: "Add Allergens",
  },
  summary: {
    id: "shared.business.allergensModal.summary",
    defaultMessage: "Summary",
  },
  addAllergen: {
    id: "shared.business.allergensModal.addAllergen",
    defaultMessage: "Add Allergen",
  },
  noAllergenSelected: {
    id: "shared.business.allergensModal.noAllergenSelected",
    defaultMessage: "There is not allergen selected",
  },
  noAllergenChildren: {
    id: "shared.business.allergensModal.noAllergenChildren",
    defaultMessage: "There is no sub category for that allergens",
  },
});
