import React, { Dispatch, SetStateAction, useEffect, useState } from "react";

import { UserForUIAdmin } from "@trace-one/api-clients.cumd";
import { Heading } from "@trace-one/design-system";
import { Card } from "@trace-one/react-components";

import useAppTranslations from "shared/hooks/useAppTranslations";

import AppIcon from "../../../../../../../components/AppIcon";
import LeavingModal from "../../../components/LeavingModal";
import styles from "../../AppsAndPermissionsSection.module.less";

import AppSwitch from "./AppSwitch";
interface ActivityCardProps {
  selectedApp?: number;
  setSelectedApp?: Dispatch<SetStateAction<number>>;
  setIsFormDirty?: any;
  roles: any;
  applicationTypeId: number;
  setRoleIds?: any;
  isFormDirty?: boolean;
  setError?: any;
  roleIds?: any;
  foundSubscriptionId?: string;
  user?: UserForUIAdmin;
}
const ActivityCard: React.FC<ActivityCardProps> = ({
  selectedApp,
  setSelectedApp,
  setIsFormDirty,
  roles,
  applicationTypeId,
  setRoleIds,
  isFormDirty,
  setError,
  roleIds,
  foundSubscriptionId,
  user,
}) => {
  const { appLabelMap } = useAppTranslations();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [pendingAppId, setPendingAppId] = useState<number | null>(null);

  const [checked, setChecked] = useState<boolean>();

  useEffect(() => {
    if (roles?.roles !== undefined && roles?.roles?.length !== 0) {
      setChecked(true);
    }
  }, [roles]);

  const changeApp = () => {
    if (pendingAppId !== null) {
      setSelectedApp(pendingAppId);
      setPendingAppId(null);
    }
  };

  const handleAppChange = (appId: number) => {
    if (isFormDirty) {
      setPendingAppId(appId);
      setIsModalOpen(true);
    } else {
      setSelectedApp(appId);
    }
  };

  return (
    <div
      className={
        applicationTypeId === selectedApp ? styles.cardActivated : styles.card
      }
    >
      <Card
        title={
          <>
            <div>
              <AppIcon type={applicationTypeId} />
            </div>
            <Heading size="xxs">{appLabelMap[applicationTypeId]}</Heading>
          </>
        }
        onClick={() => handleAppChange(applicationTypeId)}
        data-test-id={`ad-user-manage-group-access-app-card-${appLabelMap[applicationTypeId]}`}
      >
        <AppSwitch
          checked={checked}
          applicationTypeId={applicationTypeId}
          onChange={value => {
            setChecked(value);
            if (value) {
              setSelectedApp(applicationTypeId);
            } else {
              setSelectedApp(null);
              setRoleIds([]);
            }

            setIsFormDirty(true);
          }}
        />
      </Card>

      <LeavingModal
        useFor="ApplicationCards"
        visible={isModalOpen}
        setVisible={setIsModalOpen}
        changeApp={changeApp}
        setIsFormDirty={setIsFormDirty}
        setError={setError}
        user={user}
        foundSubscriptionId={foundSubscriptionId}
        roleIds={roleIds}
      />
    </div>
  );
};

export default ActivityCard;
