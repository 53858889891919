import React from "react";
import {
  Paragraph,
  Illustration,
  Card,
  Icon,
  Checkbox,
  Spinner,
} from "@trace-one/design-system";
import { FormattedMessage } from "react-intl";
import { allergensModalMessages } from "../../translations/messages";
import { SummaryListProps } from "./types";
import { getPrefixClassName, isListEmpty } from "src/utils/general";

const SummaryList: React.FC<SummaryListProps> = ({ allergensData, isLoading, selectedAllergens, setSelectedAllergens }) => {
  const prefixClassName = getPrefixClassName("allergens-modal");

  if (isLoading) {
    return (
    <div className={`${prefixClassName}-spinner`}>
      <Spinner size="large" />
    </div>
    );
  }

  if (isListEmpty(selectedAllergens)) {
    return (
      <div className={`${prefixClassName}-noAllergen`}>
        <Illustration color="grey" name="no-data" />
        <Paragraph>
        <FormattedMessage {...allergensModalMessages.noAllergenSelected} />
        </Paragraph>
      </div>
    );
  }

  return (
    <div className={`${prefixClassName}-summary`} data-test-id={`${prefixClassName}-summary`}>
      {selectedAllergens.map(allergen => {
        const childrenData = allergensData?.find(({ id }) => id === allergen.id);
        if (!childrenData) {
          return null;
        }

        return <Card key={allergen.id} title={allergen.name} className={`${prefixClassName}-summary-card`} data-test-id={`${prefixClassName}-summary-card`} type="expandable" expandIconDisabled={isListEmpty(childrenData.children)} {...(isListEmpty(childrenData.children) ? { expandIconTooltipText: <FormattedMessage {...allergensModalMessages.noAllergenChildren} /> } : {})} size="xxsmall" headerActions={[{ key: 1, type: "button", buttonType: "tertiary", args: { iconName: "trash", "data-test-id": `delete-${allergen.id}`, onClick: () => setSelectedAllergens(prev => prev.filter(x => x.id !== allergen.id)) } }]}>
          {childrenData.children.map(child => (
            <div key={`${allergen.id}-${child.id}`} className={`${prefixClassName}-summary-card-checkboxSection`}>
            <Icon name="hierarchy-link" size="small" />
            <Checkbox data-test-id={`checkbox-${allergen.name}-${child.id}`} value={child.id} checked={allergen.children.some(c => c.id === child.id)} onClick={e => {
              setSelectedAllergens(prev => {
                const newAllergens = [...prev];
                const selectedAllergen = newAllergens.find(({ id }) => id === allergen.id);
                if (e.target.checked) {
                  selectedAllergen.children.push(child);
                  return newAllergens;
                }
                selectedAllergen.children = selectedAllergen.children.filter(c => c.id !== child.id);
                return newAllergens;
              });
            }}
            >{child.name}</Checkbox>
        </div>
          ))}
        </Card>;
      })}
    </div>
  );
};

export default SummaryList;
