import React from "react";
import { useIntl } from "react-intl";

import { GroupDetails } from "@trace-one/api-clients.cumd/dist/models/group";
import { Typography } from "@trace-one/design-system";

import { formatDate } from "../../../../../shared/utils/dateUtils";

import styles from "./GroupInfoSection.module.less";

interface GroupInfoSectionProps {
  groupDetails?: GroupDetails;
}
const GroupInfoSection: React.FC<GroupInfoSectionProps> = ({
  groupDetails,
}) => {
  const { formatMessage } = useIntl();

  const numberOfActiveCompanies = groupDetails?.companies.filter(
    company => company?.isActive
  ).length;

  return (
    <div className={styles.root}>
      <div
        className={styles.groupDetails}
        data-test-id="ad-user-manage-group-access-group-details"
      >
        <div className={styles.mainDetailLabel}>
          <Typography variant="heading-xxs">
            {formatMessage({
              id: "groupList.table.groupName",
            })}
          </Typography>
          <Typography
            variant="body-regular"
            data-test-id="ad-user-manage-group-access-group-details-group-name"
          >
            {groupDetails?.groupName}
          </Typography>
        </div>

        <div className={styles.mainDetailLabel}>
          <Typography variant="heading-xxs">
            {formatMessage({
              id: "groupList.table.groupCode",
            })}
          </Typography>
          <Typography
            variant="body-regular"
            data-test-id="ad-user-manage-group-access-group-details-group-code"
          >
            {groupDetails?.groupCode}
          </Typography>
        </div>

        <div className={styles.mainDetailLabel}>
          <Typography variant="heading-xxs">
            {formatMessage({
              id: "general.creationDate",
            })}
          </Typography>
          <Typography
            variant="body-regular"
            data-test-id="ad-user-manage-group-access-group-details-created-at"
          >
            {formatDate(groupDetails?.createdAt, "DD/MM/YYYY")}
          </Typography>
        </div>

        <div className={styles.mainDetailLabel}>
          <Typography variant="heading-xxs">
            {formatMessage({
              id: "groupList.table.numberOfCompanies",
            })}
          </Typography>
          <Typography
            variant="body-regular"
            data-test-id="ad-user-manage-group-access-group-details-group-companies-number"
          >
            {numberOfActiveCompanies}
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default GroupInfoSection;
