import { useState } from "react";

import { Icon } from "@trace-one/design-system";

import RemoveFromGroupModal from "../../../../../../components/RemoveFromGroupModal";
import usePermissions from "../../../../../../core/oidc/usePermissions";
import { SharedGroupSubscriptionTableColumn } from "../../../../models";

const SubscriptionAction = ({
  record,
  fetchAllGroupDetails,
  groupId,
}: {
  record?: SharedGroupSubscriptionTableColumn;
  groupId?: string;
  fetchAllGroupDetails?: any;
}) => {
  const { hasRoleSuperAdmin, hasRoleAdministrator } = usePermissions();
  const [isRemoveFromGroupModalVisible, setIsRemoveFromGroupModalVisible] =
    useState<boolean>(false);

  return (
    <>
      {(hasRoleSuperAdmin || hasRoleAdministrator) && (
        <Icon
          name="trash"
          color="red"
          style={{ cursor: "pointer" }}
          onClick={() => setIsRemoveFromGroupModalVisible(true)}
        />
      )}

      <RemoveFromGroupModal
        isModalVisible={isRemoveFromGroupModalVisible}
        setIsModalVisible={setIsRemoveFromGroupModalVisible}
        subscriptionId={record?.subscriptionId}
        associatedCompanyId={record?.associatedCompanyId}
        fetchSharedSubscriptions={fetchAllGroupDetails}
        fetchAllGroupDetails={fetchAllGroupDetails}
        groupId={groupId}
      />
    </>
  );
};

export default SubscriptionAction;
