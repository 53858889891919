import { AxiosError } from "axios";

export enum ErrorCode {
  COMPANY_RELATION_ALREADY_EXISTS = "86676191-1cda-4e11-85de-523ff5458b5c",
  CAN_NOT_DEACTIVATE_OR_SUSPEND_TO = "6ada684c-143f-4c60-88c1-f067e35d0a41",
  CAN_NOT_DEACTIVATE_OR_SUSPEND_COMPANY_ADMIN = "7dfc9e3f-45bc-41b3-99bd-a231f05916ef",
  CAN_NOT_DEACTIVATE_OR_SUSPEND_MAIN_CONTACT = "98a8bfcf-54dd-4d59-8616-e473fdfb653b",
  EMAIL_INVALID = "4b6eaeb4-bd04-4ea9-a6d2-5b291742570d",
  EMAIL_ALREADY_EXISTS = "19bfe242-c077-49ef-b293-9ac998bbacb9",
  REACHED_MAX_NUMBER_OF_USERS = "5d4712f9-b9f4-4506-ab1d-d8eee6b46f4b",
  COMPANY_SETTINGS_NOT_FOUND = "d6f45f8a-909c-402f-b3a6-ba8e3d99c290",
  INVALID_DEFAULT_LANGUAGE_CODE = "c2bead2c-eddb-45a6-b41b-d6e250146bc8",
  ROLE_IDS_CANNOT_BE_EMPTY = "76be326f-7b5a-453d-ada3-58e526ea08c7",
}

function isAxiosError(error: any): error is AxiosError<{ errorCode?: string }> {
  return error?.isAxiosError;
}

export function hasErrorOccurred({
  error,
  errorCode,
}: {
  error: any;
  errorCode: ErrorCode;
}) {
  if (!isAxiosError(error)) {
    return false;
  }

  return error?.response?.data?.errorCode === errorCode;
}
