import { createAsyncThunk } from "@reduxjs/toolkit";

import { CumdAPI, RlmdAPI } from "apis";
import { Languages } from "translations";

import { UserStatus } from "shared/constants";

export const fetchRolesForManageAccess = createAsyncThunk(
  "userAccessManage/fetchRolesForManageAccess",
  async (
    { companyId, languageCode }: { companyId: string; languageCode: string },
    thunkAPI
  ) => {
    try {
      const { data: roles } = await CumdAPI.getRolesByCompanyId(companyId, {
        languageCode,
      });
      return { roles };
    } catch (error) {
      const status = error?.response?.status ?? 500;
      return thunkAPI.rejectWithValue({
        status,
      });
    }
  }
);

export const fetchUserForManageAccess = createAsyncThunk(
  "userAccessManage/fetchUserForManageAccess",
  async ({ userId }: { userId: string }, thunkAPI) => {
    try {
      const {
        data: { users },
      } = await CumdAPI.getUsersByFiltersForUIAdmin({
        userIds: [userId],
      });
      const user = users[0];
      if (!user) {
        return thunkAPI.rejectWithValue({ status: 404 });
      }

      if (user.userStatus !== UserStatus.ENABLED) {
        return thunkAPI.rejectWithValue({ status: 403 });
      }

      const [companyActivityId, companyActivities] = await Promise.all([
        CumdAPI.getCompanyByUserId(userId).then(
          ({ data: { companyActivityId } }) => companyActivityId
        ),
        RlmdAPI.getReferenceListItemsByReferenceListName("Company_Activity", {
          languageCode: Languages.ENGLISH,
        }).then(({ data }) => data),
      ]);

      const companyActivityGuid = companyActivities.find(
        ({ itemCode }) => itemCode === companyActivityId.toString()
      )?.id;

      return { user, companyActivityGuid };
    } catch (error) {
      const status = error?.response?.status ?? error?.status ?? 500;
      return thunkAPI.rejectWithValue({
        status,
      });
    }
  }
);

export const fetchResponsibilities = createAsyncThunk(
  "userAccessManage/fetchResponsibilities",
  async ({
    parentItemId,
    languageCode,
    companyId,
  }: {
    parentItemId?: string;
    languageCode: string;
    companyId?: string;
  }) => {
    const { data: responsibilities } =
      await RlmdAPI.getReferenceListItemsByReferenceListName(
        "Team_Member_Responsibility",
        {
          parentItemId,
          languageCode,
          companyId,
        }
      );
    return responsibilities;
  }
);
