import React from "react";
import { FormattedMessage } from "react-intl";
import { Paragraph, Button, Illustration } from "@trace-one/design-system";
import { AskAccessModal } from "IndexComponents";
import { useModalVisibility } from "src/hooks";
import { askAppAccessMessages } from "src/components/AppSwitcher/translations/messages";
import { DiscoverSolutionsProps } from "./types";
import { getPrefixClassName } from "src/utils/general";
import { AppSwitcherVariant } from "../../types";

const DiscoverSolutions: React.FC<DiscoverSolutionsProps> = ({
  handleClose,
  userId,
  variant,
}) => {
  const { isModalOpen, onOpenModal, onCloseModal } = useModalVisibility({
    onClose: handleClose,
  });

  const prefixClassName = getPrefixClassName("app-switcher");

  return (
    <>
      <div
        className={`${prefixClassName}-discover-solutions`}
        data-test-id={`${prefixClassName}-discover-solutions`}
        onClick={onOpenModal}
      >
        {variant === AppSwitcherVariant.VERTICAL
          ? (
            <>
              <Illustration color="grey" name="products" size={56}/>
              <div className={`${prefixClassName}-discover-solutions-text-area`}>
                <Button className={`${prefixClassName}-discover-solutions-link`} type="link">
                  <FormattedMessage {...askAppAccessMessages.requestAccess} />
                </Button>
                <Paragraph size="xs">
                  <FormattedMessage {...askAppAccessMessages.titleVertical} /><br />
                  <FormattedMessage {...askAppAccessMessages.traceOneSolutions} />
                </Paragraph>
              </div>
            </>
            )
          : (
            <>
              <Paragraph size="xs">
                <FormattedMessage {...askAppAccessMessages.title} />
              </Paragraph>
              <Button type="link" onClick={onOpenModal}>
                <FormattedMessage {...askAppAccessMessages.link} />
              </Button>
            </>
            )}
      </div>

      {isModalOpen && (
        <AskAccessModal open={true} onClose={onCloseModal} userId={userId} />
      )}
    </>
  );
};

export default DiscoverSolutions;
