import { defineMessages } from "react-intl";

export const sidebarNavigationMessages = defineMessages({
  quick_access: {
    id: "sidebar_navigation.quick_access",
    defaultMessage: "Quick access",
  },
  help_center: {
    id: "sidebar_navigation.help_center",
    defaultMessage: "Help Center",
  },
});
