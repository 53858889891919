import React, { useCallback, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";

import { Contract } from "@trace-one/api-clients.cumd";
import { toaster } from "@trace-one/design-system";
import { Button } from "@trace-one/design-system";
import { Menu } from "antd";
import { Dropdown } from "antd";
import moment from "moment/moment";

import { CompanyForTraceoneAdminData, RelationData } from "models";

import { useAppDispatch } from "reduxStore";
import { fetchCompanyRelations } from "reduxStore/companyRelationList/asyncActions";
import { selectRelationsSkipAndTakeQueryStats } from "reduxStore/companyRelationList/selectors";
import { clearCompanyRelations } from "reduxStore/companyRelationList/slice";

import IconExpand from "components/IconExpand";
import Table from "components/Table";
import {
  CompanyStatus,
  RelationStatus,
  RelationType,
  showNewSearchForm,
} from "shared/constants";
import useSearchFilters from "shared/hooks/useSearchFilters";
import useTablePagination from "shared/hooks/useTablePagination";
import {
  SearchFiltersStorage,
  TablePaginationStorage,
} from "shared/webStorage";

import { CumdAPI } from "../../../../apis";
import usePermissions from "../../../../core/oidc/usePermissions";
import useToast from "../../../../shared/hooks/useToast";

import styles from "./CompanyRelationList.module.less";
import ExpandedRows from "./components/ExpandedRows/ExpandedRows";
import BulkCancelClosureModal from "./components/Modals/BulkCancelClosureModal";
import BulkCloseModal from "./components/Modals/BulkCloseModal";
import useCompanyRelationTable from "./hooks/useCompanyRelationTable";
import { RelationFilter } from "./models";
import OldRelationSearch from "./OldRelationSearch";
import RelationCreate from "./RelationCreate";
import RelationSearch from "./RelationSearch";
import RelationTableHeader from "./RelationTableHeader";

interface CompanyRelationsListProps {
  company?: CompanyForTraceoneAdminData;
  companyRelations?: RelationData[];
  isLoading?: boolean;
}

const CompanyRelationList: React.FC<CompanyRelationsListProps> = ({
  company,
  companyRelations,
  isLoading,
}) => {
  const { hasRoleSuperAdmin, hasRoleAdministrator } = usePermissions();

  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();
  const toast = useToast();

  const skipAndTakeQueryStats = useSelector(
    selectRelationsSkipAndTakeQueryStats
  );

  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [expandedRowKey, setExpandedRowKey] = useState<string>(null);
  const [contracts, setContracts] = useState<Contract[]>([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [updateRelationIds, setUpdateRelationIds] = useState([]);
  const [isBulkCloseModalOpen, setIsBulkCloseModalOpen] = useState(false);
  const [isBulkCancelClosureModalOpen, setIsBulkCancelClosureModelOpen] =
    useState(false);
  const [plannedClosureRelationIds, setPlannedClosureRelationIds] = useState(
    []
  );

  const { paginationQuery, setPaginationQuery, resetPageNumber } =
    useTablePagination({
      skipAndTakeQueryStats,
      webStorage: TablePaginationStorage.TO_ADMIN_RELATION_LIST,
    });

  const {
    searchText,
    setSearchText,
    filterObj,
    mergeFilters,
    removeAllFiltersAndResetPageNumber,
  } = useSearchFilters<RelationFilter>(
    {
      relationType: undefined,
      relatedCompany: undefined,
      relationStatuses: [],
      fromTargetSuspendDate: undefined,
      toTargetSuspendDate: undefined,
    },
    {
      resetPageNumber,
      webStorage: SearchFiltersStorage.TO_ADMIN_RELATION_LIST,
    }
  );

  const refetchRelations = useCallback(() => {
    const {
      relationType,
      relatedCompany,
      relationStatuses,
      fromTargetSuspendDate,
      toTargetSuspendDate,
    } = filterObj;

    let isCompanyIdOwner = undefined;
    if (relationType === RelationType.ANIMATOR) {
      isCompanyIdOwner = true;
    }
    if (relationType === RelationType.CONTRIBUTOR) {
      isCompanyIdOwner = false;
    }

    let isEnabled = undefined;
    if (
      relationStatuses.length === 1 &&
      relationStatuses.includes(RelationStatus.ACTIVE)
    ) {
      isEnabled = true;
    }
    if (
      relationStatuses.length === 1 &&
      relationStatuses.includes(RelationStatus.INACTIVE)
    ) {
      isEnabled = false;
    }

    dispatch(
      fetchCompanyRelations({
        companyId: company.companyId,
        isCompanyIdOwner,
        partnerCompanyId: relatedCompany,
        isEnabled,
        relationId: searchText,
        fromTargetSuspendDate:
          fromTargetSuspendDate &&
          moment(fromTargetSuspendDate).format("YYYY-MM-DD"),
        toTargetSuspendDate:
          toTargetSuspendDate &&
          moment(toTargetSuspendDate).format("YYYY-MM-DD"),
        skip: paginationQuery.skip,
        take: paginationQuery.take,
      })
    );
  }, [filterObj, searchText, paginationQuery]);

  useEffect(() => {
    return () => {
      dispatch(clearCompanyRelations());
    };
  }, []);

  useEffect(() => {
    refetchRelations();
  }, [refetchRelations]);

  useEffect(() => {
    setUpdateRelationIds(
      companyRelations
        .filter(
          relation => selectedRowKeys.includes(relation.id) && relation.enabled
        )
        .map(relation => relation.id)
    );
    setPlannedClosureRelationIds(
      companyRelations
        .filter(
          relation =>
            selectedRowKeys.includes(relation.id) && relation.targetSuspendDate
        )
        .map(relation => relation.id)
    );
  }, [selectedRowKeys]);

  const closeExpandedRows = () => {
    setExpandedRowKey(null);
  };

  const clearSelectedRowKeys = () => {
    setSelectedRowKeys([]);
  };

  const { columns, data } = useCompanyRelationTable({
    companyId: company.companyId,
    companyRelations,
    refetchRelations,
    closeExpandedRows,
    clearSelectedRowKeys,
    company,
  });

  const refetchContracts = async (relation: RelationData) => {
    try {
      let {
        data: { contracts },
      } = await CumdAPI.getContractsByFilters({
        ownerCompanyId: relation.ownerCompanyId,
        associatedCompanyId: relation.associatedWithCompanyId,
      });

      setContracts(contracts);
    } catch (error) {}
  };

  const disableRelations = ({ relationIds }) => {
    relationIds.forEach(async id => {
      try {
        await CumdAPI.disableRelation(id);
        refetchRelations();
        closeExpandedRows();
        setSelectedRowKeys([]);
      } catch (error) {
        toast.saveError({ error });
      }
    });

    toaster.open({
      message: formatMessage({
        id: "companyRelationList.actions.manageClosure.schedule.toaster.title",
      }),
      description: formatMessage(
        {
          id: "companyRelationList.actions.manageClosure.schedule.toaster.bulk.description",
        },
        {
          number: relationIds.length.toString(),
        }
      ),
      type: "confirmation",
    });
  };

  const scheduleClosures = async ({ relationIds, targetSuspendDate }) => {
    try {
      await CumdAPI.updateRelationsSuspension({
        relationIds: relationIds,
        targetSuspendDate,
      });
      refetchRelations();
      closeExpandedRows();
      setSelectedRowKeys([]);
      toaster.open({
        message: formatMessage({
          id: "companyRelationList.actions.manageClosure.schedule.toaster.title",
        }),
        description: formatMessage(
          {
            id: "companyRelationList.actions.manageClosure.schedule.toaster.bulk.description",
          },
          {
            number: relationIds.length.toString(),
          }
        ),
        type: "confirmation",
      });
    } catch (error) {
      toast.saveError({ error });
    }
  };

  const cancelScheduledClosures = async (relationIds: string[]) => {
    try {
      await CumdAPI.updateRelationsSuspension({
        relationIds,
        targetSuspendDate: null,
      });
      refetchRelations();
      closeExpandedRows();
      clearSelectedRowKeys();
      toaster.open({
        message: formatMessage({
          id: "companyRelationList.actions.manageClosure.cancel.toaster.title",
        }),
        description: formatMessage(
          {
            id: "companyRelationList.actions.manageClosure.schedule.toaster.bulk.description",
          },
          {
            number: relationIds.length.toString(),
          }
        ),
        type: "confirmation",
      });
    } catch (error) {
      toast.saveError({ error });
    }
  };

  return (
    <>
      <div className={styles.topContainer}>
        <RelationCreate
          company={company}
          isModalVisible={isModalVisible}
          setIsModalVisible={setIsModalVisible}
          refetchRelations={refetchRelations}
        />
        {showNewSearchForm ? (
          <RelationSearch
            filterObj={filterObj}
            mergeFilters={mergeFilters}
            initialSearchValue={searchText}
            onSearch={setSearchText}
            onClearFiltersClick={removeAllFiltersAndResetPageNumber}
            company={company}
          />
        ) : (
          <OldRelationSearch
            filterObj={filterObj}
            mergeFilters={mergeFilters}
            initialSearchValue={searchText}
            onSearch={setSearchText}
            onClearFiltersClick={removeAllFiltersAndResetPageNumber}
            company={company}
          />
        )}

        {(hasRoleSuperAdmin || hasRoleAdministrator) &&
          company.companyStatus === CompanyStatus.ENABLED && (
            <Button
              onClick={() => {
                setIsModalVisible(true);
              }}
              data-test-id="create-new-relation-button"
            >
              {formatMessage({ id: "companyRelationList.createNewRelation" })}
            </Button>
          )}
      </div>

      <RelationTableHeader
        listingResult={
          <div className={styles.headerWrapper}>
            <div>
              {formatMessage(
                { id: "companyRelationList.tableHeader.listingResultNumber" },
                {
                  current: skipAndTakeQueryStats.currentCount ?? 0,
                  total: skipAndTakeQueryStats.totalCount ?? 0,
                }
              )}
            </div>

            {(hasRoleSuperAdmin || hasRoleAdministrator) && (
              <Dropdown
                trigger={["click"]}
                disabled={updateRelationIds.length === 0}
                overlay={
                  <Menu>
                    <Menu.Item
                      onClick={() => setIsBulkCloseModalOpen(true)}
                      key="close-relations"
                      data-test-id="close-relations"
                    >
                      {formatMessage({
                        id: "companyRelationList.actions.closeRelation",
                      })}
                    </Menu.Item>
                    <Menu.Item
                      onClick={() => setIsBulkCancelClosureModelOpen(true)}
                      key="cancel-scheduled-relations"
                      data-test-id="cancel-scheduled-relations"
                      disabled={!plannedClosureRelationIds.length}
                    >
                      {formatMessage({
                        id: "companyRelationList.actions.closeRelation.radioBtn.close.cancel",
                      })}
                    </Menu.Item>
                  </Menu>
                }
              >
                <Button
                  type="secondary"
                  iconName="arrow-down"
                  iconPlacement="right"
                >
                  {formatMessage({ id: "general.actions" })}
                </Button>
              </Dropdown>
            )}
          </div>
        }
      />

      <Table
        columns={columns}
        dataSource={data}
        rowSelection={{
          type: "checkbox",
          selectedRowKeys: selectedRowKeys,
          onChange: setSelectedRowKeys,
        }}
        expandable={{
          expandIcon: props => <IconExpand {...props} />,
          expandedRowRender: () => <ExpandedRows contracts={contracts} />,
          onExpand: async (expanded, record) => {
            await refetchContracts(record.rawData);
            setExpandedRowKey(expanded ? record.key : null);
          },
          expandedRowKeys: [expandedRowKey],
        }}
        loading={isLoading}
        skip={paginationQuery.skip}
        take={paginationQuery.take}
        skipAndTakeQueryStats={skipAndTakeQueryStats}
        setPaginationQuery={setPaginationQuery}
      />

      <BulkCloseModal
        visible={isBulkCloseModalOpen}
        setVisible={setIsBulkCloseModalOpen}
        disableRelations={disableRelations}
        scheduleClosures={scheduleClosures}
        companyName={company.companyDisplayName}
        relationIds={updateRelationIds}
      />

      <BulkCancelClosureModal
        visible={isBulkCancelClosureModalOpen}
        setVisible={setIsBulkCancelClosureModelOpen}
        cancelScheduledCloseRelations={cancelScheduledClosures}
        companyName={company.companyDisplayName}
        relationIds={plannedClosureRelationIds}
      />
    </>
  );
};

export default CompanyRelationList;
