import React from "react";
import { useIntl } from "react-intl";

import { Modal, Heading, toaster } from "@trace-one/design-system";

import { CumdAPI } from "../../../../../../apis";
import { ErrorCode } from "../../../../../../shared/errors";
import useToast from "../../../../../../shared/hooks/useToast";

const LeavingModal = ({
  useFor,
  visible,
  setVisible,
  changeCompany,
  setIsFormDirty,
  setError,
  user,
  foundSubscriptionId,
  roleIds,
  changeApp,
}: {
  useFor?: string;
  setVisible: (boolean) => void;
  visible: boolean;
  changeCompany?: () => void;
  setIsFormDirty?: any;
  setError?: any;
  user?: any;
  foundSubscriptionId?: string;
  roleIds?: any;
  changeApp?: () => void;
}) => {
  const { formatMessage } = useIntl();
  const toast = useToast();

  const handleCancel = () => {
    setVisible(false);
    useFor === "CompanySelect" ? changeCompany() : changeApp();
    setIsFormDirty(false);
  };

  const onSubmitGroupAccess = async () => {
    try {
      await CumdAPI.shareRolesToUser(user?.userId, {
        sharedSubscriptionId: foundSubscriptionId,
        roleIds: roleIds,
      });
      toast.saveSuccess();
      setIsFormDirty(false);
      setTimeout(function () {
        window.location.reload();
      }, 500);
    } catch (error) {
      if (
        error.response.data.errorCode === ErrorCode.ROLE_IDS_CANNOT_BE_EMPTY
      ) {
        setError(true);
        toaster.open({
          message: formatMessage({
            id: "toast.alert",
          }),
          description: formatMessage({
            id: "userAccessManage.applications.emptyRoles",
          }),
          type: "alert",
        });
      } else {
        toast.saveError({ error });
      }
    }
  };

  const handleSave = async () => {
    try {
      await onSubmitGroupAccess();
      setVisible(false);
      useFor === "CompanySelect" ? changeCompany() : changeApp();
    } catch (error) {}
  };

  return (
    <Modal.Simple
      size="s"
      children={
        <Heading size="s" color="grey-4">
          {formatMessage({
            id: "userAccessManage.groupAccess.leavingModal.title",
          })}
        </Heading>
      }
      open={visible}
      shouldUseLinkButton
      primaryButtonText={formatMessage({ id: "general.save" }).toUpperCase()}
      secondaryButtonText={formatMessage({
        id: "general.cancel",
      }).toUpperCase()}
      onPrimaryButtonClick={() => {
        handleSave();
      }}
      onSecondaryButtonClick={() => {
        handleCancel();
      }}
      onCancel={handleCancel}
    />
  );
};

export default LeavingModal;
