import React from "react";
import { Typography } from "@trace-one/design-system";
import { IntructionItemProps } from "../../types";

const IntructionItem: React.FC<IntructionItemProps> = ({
  itemNumber,
  content,
  children,
}) => {
  return (
    <div className="ds-d-flex instruction-item ds-gap-1">
      <div className="ds-d-flex ds-align-items-center ds-gap-1">
        <div className="item-number">{itemNumber}</div>
        <Typography className="item-content" variant="body-regular">
          {content}
        </Typography>
      </div>
      <div className="instruction-item-slot">{children}</div>
    </div>
  );
};

export default IntructionItem;
