import React, { useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";

import { Container } from "@trace-one/design-system";

import styles from "./StickyTabContainer.module.less";
import StickyTabHeader from "./StickyTabHeader";

export interface StickyTabContainerProps {
  title: React.ReactNode;
  description?: React.ReactNode;
  onBack: () => void;
  tabs: {
    label: React.ReactNode;
    content?: React.ReactNode;
  }[];
  mode?: string;
  companyId?: string;
  companyStatus?: string;
  isSubmitting?: boolean;
  onCancel?: () => void;
  selectedTab?: number;
  onTabChange?: any;
  isFormDirty?: boolean;
  setIsFormDirty?: any;
}

const StickyTabContainer: React.FC<StickyTabContainerProps> = ({
  title,
  description,
  onBack,
  tabs,
  mode,
  companyId,
  companyStatus,
  isSubmitting,
  onCancel,
  selectedTab: externalSelectedTab,
  onTabChange: externalOnTabChange,
  isFormDirty,
  setIsFormDirty,
}) => {
  const { formatMessage } = useIntl();

  const [internalSelectedTab, setInternalSelectedTab] = useState(0);
  const previousTab = useRef(internalSelectedTab);

  const selectedTab =
    externalSelectedTab !== undefined
      ? externalSelectedTab
      : internalSelectedTab;

  useEffect(() => {
    previousTab.current = selectedTab;
  }, [selectedTab]);

  const onTabChange = (tabIndex: string) => {
    const newTabIndex = parseInt(tabIndex);

    if (isFormDirty && selectedTab === 1 && newTabIndex === 0) {
      const confirmLeave = window.confirm(
        formatMessage({ id: "general.leavingPrompt" })
      );
      if (!confirmLeave) {
        if (externalOnTabChange) {
          externalOnTabChange(previousTab.current);
        } else {
          setInternalSelectedTab(previousTab.current);
        }
        return;
      }
      setIsFormDirty(false);
    }

    if (externalOnTabChange) {
      externalOnTabChange(newTabIndex);
    } else {
      setInternalSelectedTab(newTabIndex);
    }
  };

  return (
    <div className={styles.root}>
      <StickyTabHeader
        title={title}
        description={description}
        onBack={onBack}
        tabs={tabs}
        mode={mode}
        onTabChange={onTabChange}
        companyId={companyId}
        companyStatus={companyStatus}
        isSubmitting={isSubmitting}
        onCancel={onCancel}
        selectedTab={selectedTab}
      />
      <Container className={styles.content}>
        {tabs[selectedTab].content}
      </Container>
    </div>
  );
};

export default StickyTabContainer;
