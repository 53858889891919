import { UserForUIAdminData, RoleForCompanyData } from "models";

import { UserAccessManageValues, RoleId } from "../models";

function getDefaultValues({
  user,
  rolesMap,
}: {
  user: UserForUIAdminData;
  rolesMap: { [applicationTypeId: string]: RoleForCompanyData };
}): UserAccessManageValues {
  const applicationRoles = user?.applicationTypeIds?.reduce<{
    [applicationTypeId: string]: RoleId[];
  }>((prev, current) => {
    const roleForApp = rolesMap[current];
    if (!roleForApp) {
      return prev;
    }

    return {
      ...prev,
      [current]:
        roleForApp.roles
          .filter(role => user.roleIds.includes(role.roleId))
          .map(({ roleId }) => roleId) ?? [],
    };
  }, {});

  return {
    isAccountAdministrator: user?.isAccountAdministrator,
    responsibilities: user?.responsibilities,
    applicationRoles,
  };
}

export default getDefaultValues;
