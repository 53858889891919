import { Toggle } from "@trace-one/design-system";

interface AppSwitchProps {
  checked?: boolean;
  onChange?: (status: boolean) => void;
  applicationTypeId?: number;
  disabled?: boolean;
}

const AppSwitch: React.FC<AppSwitchProps> = ({
  checked,
  onChange,
  applicationTypeId,
  disabled,
}) => {
  return (
    <div
      data-test-id={`ad-user-manage-group-access-app-card-switcher-${applicationTypeId}`}
    >
      <Toggle
        checked={checked}
        onChange={(value, event) => {
          event.stopPropagation();
          onChange(value);
        }}
        size="small"
        disabled={disabled}
      />
    </div>
  );
};

export default AppSwitch;
