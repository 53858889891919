import { useEffect } from "react";

import Spinner from "components/Spinner";
import Table from "components/Table";

import useAllGroupDetails from "../../../pages/GroupDetailsPage/hooks/useAllGroupDetails";
import styles from "../GeneralInformation/GeneralInformation.module.less";
import useSharedSubscriptionsTable from "../hooks/userSharedSubscriptionsTable";

import SharedSubscriptionsTableHeader from "./SharedSubscriprionsTableHeader";
import SharedSubscriptionBanner from "./SharedSubscriptionBanner";

const SharedSubscriptions = ({ groupId }: { groupId?: string }) => {
  const { fetchAllGroupDetails, groupAllDetails, isInitialazed } =
    useAllGroupDetails();

  useEffect(() => {
    fetchAllGroupDetails(groupId);
  }, [groupId]);
  const numberOfSubscriptions = groupAllDetails?.companies?.flatMap(company =>
    company?.sharedSubscriptions?.flatMap(
      subscription => subscription?.sharedCompanies
    )
  ).length;
  const numberOfActiveCompanies = groupAllDetails?.companies?.filter(
    company => company?.isActive
  ).length;

  const allSharedSubscriptions =
    groupAllDetails?.companies?.flatMap(
      company => company.sharedSubscriptions || []
    ) || [];

  const { columns, data } = useSharedSubscriptionsTable({
    allSharedSubscriptions,
    groupAllDetails,
    groupId,
    fetchAllGroupDetails,
  });

  if (!isInitialazed) {
    return <Spinner underHeader />;
  }

  return (
    <div className={styles.root}>
      <SharedSubscriptionBanner
        groupDetails={groupAllDetails}
        numberOfActiveCompanies={numberOfActiveCompanies}
      />
      <SharedSubscriptionsTableHeader currentNumber={numberOfSubscriptions} />
      <Table columns={columns} dataSource={data} hidePagination />
    </div>
  );
};

export default SharedSubscriptions;
