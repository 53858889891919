import React, { useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";

import { Filter } from "@trace-one/business-components";
import { DateRangePicker, FilterProps, Input } from "@trace-one/design-system";
import moment from "moment/moment";

import { adjustIntercomButton } from "shared/utils/toggleIntercom";

import { CumdAPI } from "../../../../apis";
import AsyncPickerDropdown from "../../../../components/AsyncPickerDropdown";
import { SearchFiltersProps } from "../../../../components/SearchFilters";
import { selectUserLanguageCode } from "../../../../reduxStore/user/selectors";
import useToast from "../../../../shared/hooks/useToast";
import { SelectOption } from "../../../../shared/typings";
import AsyncCompanySelect from "../../../Users/containers/TraceoneAdminUserList/components/CompanyAsyncSelect";
import { GroupFilter } from "../../models";

import styles from "./GroupSearch.module.less";

interface GroupSearchProps {
  filterObj: GroupFilter;
  mergeFilters: (
    value: Partial<GroupFilter> | ((prevState: GroupFilter) => GroupFilter)
  ) => void;
  initialSearchValue: string;
  onSearch: SearchFiltersProps["onSearch"];
  onClearFiltersClick: SearchFiltersProps["onClearFiltersClick"];
}

const GroupSearch: React.FC<GroupSearchProps> = ({
  filterObj,
  mergeFilters,
  initialSearchValue,
  onSearch,
  onClearFiltersClick,
}) => {
  const showIntercom = useRef(true);

  const { formatMessage } = useIntl();
  const languageCode = useSelector(selectUserLanguageCode);
  const toast = useToast();

  const [companyLabel, setCompanyLabel] = useState("");

  const [selectedFilters, setSelectedFilters] = useState<
    FilterProps["selectedFilters"]
  >([]);

  useEffect(() => {
    const {
      companyId,
      groupName,
      groupCode,
      fromTargetCreationDate,
      toTargetCreationDate,
    } = filterObj;

    const defaultSelectedFilters = [
      groupName && {
        filterId: "1",
        values: [groupName],
      },

      groupCode && {
        filterId: "2",
        values: [groupCode],
      },

      (fromTargetCreationDate || toTargetCreationDate) && {
        filterId: "4",
        values: [fromTargetCreationDate + "-" + toTargetCreationDate],
      },

      companyId && {
        filterId: "4",
        values: [companyId],
      },
    ].filter(i => !!i);

    setSelectedFilters(defaultSelectedFilters);
  }, [filterObj]);

  const getCompaniesOptions = ({ searchValue }: { searchValue?: string }) => {
    return CumdAPI.getCompaniesByFiltersForToAdmin(
      {},
      {
        searchText: searchValue,
      }
    ).then(({ data }) => {
      return data.companies.map(({ companyId, ...rest }) => ({
        value: companyId,
        label: rest.companyDisplayName,
      }));
    });
  };

  const useDefaultOptionsCompany = () => {
    const [defaultOptions, setDefaultOptions] = useState<SelectOption[]>([]);
    useEffect(() => {
      getCompaniesOptions({})
        .then(result => {
          setDefaultOptions(result);
        })
        .catch(() => {});
    }, []);

    return defaultOptions;
  };

  const defaultOptions = useDefaultOptionsCompany();

  const fetchCompaniesOnSearch = searchText => {
    return getCompaniesOptions({ searchValue: searchText })
      .then(companies => {
        return companies;
      })
      .catch(error => {
        toast.saveError({ error });

        return [];
      });
  };

  const filters = [
    {
      title: formatMessage({ id: "groupList.table.groupName" }),
      clearSelection: () => {
        mergeFilters({
          groupName: undefined,
        });
      },
      elementToRender: (
        <Input
          placeholder={formatMessage({
            id: "general.enterValue",
          })}
          value={filterObj.groupName}
          onChange={e => mergeFilters({ groupName: e.target.value })}
          allowClear
          showSearch
          data-test-id="ad-groups-filter-groupName"
        />
      ),
      filterId: "1",
    },

    {
      title: formatMessage({ id: "groupList.table.groupCode" }),
      clearSelection: () => {
        mergeFilters({
          groupCode: undefined,
        });
      },
      elementToRender: (
        <Input
          placeholder={formatMessage({
            id: "general.enterValue",
          })}
          value={filterObj.groupCode}
          onChange={e => mergeFilters({ groupCode: e.target.value })}
          allowClear
          showSearch
          data-test-id="ad-groups-filter-groupCode"
        />
      ),
      filterId: "2",
    },

    {
      title: formatMessage({ id: "general.company" }),
      controlType: "single_selection",
      clearSelection: () => {
        mergeFilters({});
      },
      elementToRender: (
        <AsyncCompanySelect
          value={filterObj.companyId}
          label={companyLabel}
          onChange={companyId => {
            mergeFilters({ companyId });
          }}
          onLabelChange={label => {
            setCompanyLabel(label);
          }}
          id="ad-filter-companyId"
          data-test-id="ad-groups-filter-company"
        />
      ),
      quickFilter: (
        <AsyncPickerDropdown
          title={formatMessage({ id: "general.company" })}
          showBadgeCount
          defaultOptions={defaultOptions}
          value={filterObj.companyId ? [filterObj.companyId] : []}
          selectionType="single"
          onSearch={undefined}
          minLengthToSearch={3}
          onAsyncSearch={fetchCompaniesOnSearch}
          searchOnChange
          showSearchInput
          onChange={({ selectedItem }) => {
            mergeFilters({
              companyId: selectedItem.value,
            });
            setCompanyLabel(selectedItem.label);
          }}
          clearSelection={() =>
            mergeFilters({
              companyId: undefined,
            })
          }
          data-test-id="ad-groups-filter-picker-company"
        />
      ),
      filterId: "3",
    },

    {
      title: formatMessage({ id: "general.creationDate" }),
      controlType: "single_selection",
      clearSelection: () => {
        mergeFilters({
          fromTargetCreationDate: undefined,
          toTargetCreationDate: undefined,
        });
      },
      elementToRender: (
        <DateRangePicker
          allowEmpty={[true, true]}
          value={[
            filterObj.fromTargetCreationDate
              ? moment(filterObj.fromTargetCreationDate)
              : undefined,
            filterObj.toTargetCreationDate
              ? moment(filterObj.toTargetCreationDate)
              : undefined,
          ]}
          defaultValue={[
            filterObj.fromTargetCreationDate
              ? moment(filterObj.fromTargetCreationDate)
              : undefined,
            filterObj.toTargetCreationDate
              ? moment(filterObj.toTargetCreationDate)
              : undefined,
          ]}
          onChange={dateRange => {
            mergeFilters({
              fromTargetCreationDate: dateRange?.[0]
                ? moment(dateRange[0]).toISOString()
                : undefined,
              toTargetCreationDate: dateRange?.[1]
                ? moment(dateRange[1]).toISOString()
                : undefined,
            });
          }}
          locale={languageCode}
          disabledDate={current => {
            return current && current.valueOf() >= Date.now();
          }}
          data-test-id="ad-groups-filter-creationDate"
        />
      ),
      filterId: "4",
    },
  ];

  return (
    <div className={styles.root}>
      <Filter
        filters={filters}
        selectedFilters={selectedFilters}
        clearAllFilters={() => {
          onClearFiltersClick();
        }}
        search={{
          defaultValue: initialSearchValue,
          placeholder: formatMessage({
            id: "groupList.filters.searchText.placeholder",
          }),
          onSearch: query => {
            onSearch(query);
          },
        }}
        onAllFilterPanelToggle={() => {
          showIntercom.current = !showIntercom.current;
          adjustIntercomButton(showIntercom.current);
        }}
        history={{
          listen: callback => {
            // console.log("Listening to history changes");
          },
        }}
      />
    </div>
  );
};

export default GroupSearch;
