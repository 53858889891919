import React, { Dispatch, SetStateAction } from "react";
import { useIntl } from "react-intl";

import { UserForUIAdmin } from "@trace-one/api-clients.cumd";
import { Input } from "@trace-one/design-system";

import styles from "../../AppsAndPermissionsSection.module.less";

import ActivityCard from "./ActivityCard";
interface AppsProps {
  selectedCompanyName?: string;
  sharedSubscriptionInfo?: any;
  selectedApp: number;
  setSelectedApp: Dispatch<SetStateAction<number>>;
  setIsFormDirty?: any;
  roles: any;
  setRoleIds?: any;
  isFormDirty?: boolean;
  setError?: any;
  roleIds?: any;
  foundSubscriptionId?: string;
  user?: UserForUIAdmin;
}
const Apps: React.FC<AppsProps> = ({
  selectedCompanyName,
  sharedSubscriptionInfo,
  selectedApp,
  setSelectedApp,
  setIsFormDirty,
  roles,
  setRoleIds,
  isFormDirty,
  setError,
  roleIds,
  foundSubscriptionId,
  user,
}) => {
  const { formatMessage } = useIntl();

  return (
    <div
      className={styles.appsContainer}
      data-test-id="ad-user-manage-group-access-apps"
    >
      <div className={styles.company}>
        <Input
          value={selectedCompanyName}
          disabled
          label={formatMessage({ id: "general.companyGroup" })}
          data-test-id="ad-user-manage-group-access-apps-input"
        />
      </div>
      <div className={styles.appsCards}>
        {sharedSubscriptionInfo?.map(app => {
          return (
            <ActivityCard
              key={app.applicationTypeId}
              selectedApp={selectedApp}
              setSelectedApp={setSelectedApp}
              setIsFormDirty={setIsFormDirty}
              applicationTypeId={app.applicationTypeId}
              setRoleIds={setRoleIds}
              roles={roles?.find(
                role => role.applicationTypeId === app.applicationTypeId
              )}
              isFormDirty={isFormDirty}
              setError={setError}
              roleIds={roleIds}
              foundSubscriptionId={foundSubscriptionId}
              user={user}
            />
          );
        })}
      </div>
    </div>
  );
};

export default Apps;
