import React, { useState, useEffect } from "react";
import { useIntl } from "react-intl";

import { Tooltip } from "@trace-one/design-system";
import { Label } from "@trace-one/react-components";

import Select, { SelectProps } from "components/Select";

import styles from "../../AppsAndPermissionsSection.module.less";

interface RolesSelectProps extends SelectProps {
  subscriptionRolesAndPermissions?: any;
  setRoleIds?: any;
  roleIds?: string[];
  sharedRoles?: any;
  setIsFormDirty?: any;
  error?: boolean;
  setError?: any;
}

const RolesSelect: React.FC<RolesSelectProps> = ({
  subscriptionRolesAndPermissions,
  setRoleIds,
  roleIds,
  sharedRoles,
  setIsFormDirty,
  error,
  setError,
}) => {
  const { formatMessage } = useIntl();
  const [confirmationOpen, setConfirmationOpen] = useState(false);

  const [selectError, setSelectError] = useState(false);

  console.log("selectError", selectError);
  console.log("roleIds", roleIds);
  useEffect(() => {
    if (
      sharedRoles &&
      sharedRoles.length > 0 &&
      subscriptionRolesAndPermissions
    ) {
      const subscriptionRolesMap = subscriptionRolesAndPermissions?.reduce(
        (acc, role) => {
          acc[role?.roleId] = role;
          return acc;
        },
        {}
      );

      const newRoleIds = sharedRoles?.flatMap(company =>
        company.roles
          .filter(role => subscriptionRolesMap[role.roleId])
          .map(role => role.roleId)
      );

      setRoleIds(newRoleIds);
    }
  }, [sharedRoles, subscriptionRolesAndPermissions]);

  const rolesOptions = subscriptionRolesAndPermissions?.map(
    ({ name, roleId }) => ({
      label: name,
      value: roleId,
    })
  );
  const handleOnChange = (newValue: string[]) => {
    if (newValue.length === 0) {
      setSelectError(true);
    } else {
      setSelectError(false);
    }

    setRoleIds(newValue);
    setError(false);

    const sharedRoleIds = sharedRoles?.flatMap(company =>
      company.roles.map(role => role.roleId)
    );

    if (JSON.stringify(newValue) !== JSON.stringify(sharedRoleIds)) {
      setIsFormDirty(true);
    } else {
      setIsFormDirty(false);
    }
  };

  return (
    <div className={styles.rolesSelect}>
      <Label
        required
        title={formatMessage({ id: "general.roles" })}
        mode="horizontal"
      />
      <Tooltip
        placement="left"
        text={formatMessage(
          {
            id: "userAccessManage.switchApp.confirm",
          },
          { br: <br /> }
        )}
        actions={[
          {
            text: formatMessage({ id: "general.no" }),
            onClick: () => {
              setConfirmationOpen(false);
            },
          },
          {
            text: formatMessage({ id: "general.yes" }),
            onClick: () => {
              setRoleIds([]);
              setSelectError(true);
              setConfirmationOpen(false);
            },
          },
        ]}
        visible={confirmationOpen}
        data-test-id="ad-user-manage-group-access-roles-select-tooltip"
      >
        <Select
          mode="multiple"
          value={roleIds}
          onChange={handleOnChange}
          options={rolesOptions}
          allowClear
          onClear={() => {
            if (roleIds.length > 1) {
              setConfirmationOpen(true);
            }
          }}
          error={error || selectError}
          errorMessage={formatMessage({
            id: "userAccessManage.applications.emptyRoles",
          })}
          data-test-id="ad-user-manage-group-access-roles-select"
        />
      </Tooltip>
    </div>
  );
};

export default RolesSelect;
