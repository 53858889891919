import { AppListProps } from "./components/AppList/types";
import { DiscoverSolutionsProps } from "./components/DiscoverSolutions/types";
import { UserApplication } from "../../models";

export interface AppSwitcherProps {
  accessToken: string;
  userId: string;
  excludeApps?: number[];
  showDiscoverSolutions?: boolean;
  withIntercomTrack?: boolean;
  handleClose?: DiscoverSolutionsProps["handleClose"];
  handleCreationPopUp?: AppListProps["handleCreationPopUp"];
  fetchCompaniesByIds?: Function;
  variant?: VariantType;
  includeApps?: UserApplication[];
  orderAppsById?: boolean;
}

export enum AppSwitcherVariant {
  GRID = "grid",
  VERTICAL = "vertical",
}

export type VariantType = AppSwitcherVariant.GRID | AppSwitcherVariant.VERTICAL;
