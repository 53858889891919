import React, { useState } from "react";
import { withLanguageData, AppSwitcher } from "IndexComponents";
import { LangList } from "../../../utils/const";

import { Divider } from "@trace-one/design-system";
import "./styles.less";
import { SidebarNavigationProps } from "./types";
import { getPrefixClassName } from "../../utils/general";

import classnames from "classnames";
import HelperButton from "./components/HelperButton";
import SidebarHeader from "./components/SidebarHeader";

import { sidebarNavigationMessages } from "./translations/defineMessage";
import { FormattedMessage } from "react-intl";
import enUs from "./translations/en-US.json";
import frFr from "./translations/fr-FR.json";
import deDe from "./translations/de-DE.json";
import esEs from "./translations/es-ES.json";
import ptPt from "./translations/pt-PT.json";
import itIt from "./translations/it-IT.json";
import nlBe from "./translations/nl-BE.json";

const DEFAULT_LANG = enUs;

const enhance = withLanguageData({
  translations: {
    [LangList.EN_US]: enUs,
    [LangList.FR_FR]: { ...DEFAULT_LANG, ...frFr },
    [LangList.DE_DE]: { ...DEFAULT_LANG, ...deDe },
    [LangList.ES_ES]: { ...DEFAULT_LANG, ...esEs },
    [LangList.PT_PT]: { ...DEFAULT_LANG, ...ptPt },
    [LangList.IT_IT]: { ...DEFAULT_LANG, ...itIt },
    [LangList.NL_BE]: { ...DEFAULT_LANG, ...nlBe },
  },
});

export const SidebarNavigation: React.FC<SidebarNavigationProps> = ({
  appName,
  appLogo,
  homeUrl,
  appSwitcherProps,
  helpLinks,
  children,
  collapsed: initialCollapsed,
  handleCollapse,
}) => {
  const [collapsed, setCollapsed] = useState(initialCollapsed);
  const prefixCls = getPrefixClassName("sidebar-navigation");

  function onToggleCollapsed (collapsed: boolean): void {
    setCollapsed(collapsed);
    handleCollapse(collapsed);
  }

  return (
    <div
      className={classnames(
        prefixCls,
        `${prefixCls}-${collapsed ? "close" : "open"}`
      )}
    >
      <section>
        <SidebarHeader
          appName={appName}
          appLogo={appLogo}
          homeUrl={homeUrl}
          collapsed={collapsed}
          onToggleCollapsed={onToggleCollapsed}
        />
        {children}
        <div className={`${prefixCls}-divider`}>
          <Divider color="grey-2" />
        </div>
        <h5 className={`${prefixCls}-apps-header`}>
          <FormattedMessage {...sidebarNavigationMessages.quick_access} />
        </h5>
        <AppSwitcher
          {...appSwitcherProps}
          showDiscoverSolutions={!collapsed}
          variant="vertical"
          orderAppsById={true}
        />
      </section>
      <HelperButton helpLinks={helpLinks} />
    </div>
  );
};

export default enhance(SidebarNavigation);
