import { useState } from "react";
import { useIntl } from "react-intl";

import { Container, Button } from "@trace-one/design-system";
import { Title } from "@trace-one/react-components";

import GroupModal from "../../../../components/GroupModal";
import { GroupModalMode } from "../../../../shared/constants";
import GroupList from "../../containers/GroupList";

import styles from "./GroupListPage.module.less";
export const GroupListPage: React.FC = () => {
  const { formatMessage } = useIntl();

  const [isCreateGroupModalOpen, setIsCreateGroupModalOpen] = useState(false);
  const onModalClose = () => setIsCreateGroupModalOpen(false);

  return (
    <Container isPageWrapper>
      {!isCreateGroupModalOpen && (
        <>
          <div className={styles.titleWrapper}>
            <>
              <Title
                value={formatMessage({
                  id: "groupList.title",
                }).toUpperCase()}
                level={1}
              />
              <Button onClick={() => setIsCreateGroupModalOpen(true)}>
                {formatMessage({ id: "general.createGroup" }).toUpperCase()}
              </Button>
            </>
          </div>
          <GroupList />
        </>
      )}
      <GroupModal
        mode={GroupModalMode.CREATE_GROUP}
        open={isCreateGroupModalOpen}
        onClose={onModalClose}
        setIsCreateGroupModalOpen={setIsCreateGroupModalOpen}
      />
    </Container>
  );
};

export default GroupListPage;
