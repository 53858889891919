import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useParams, useHistory } from "react-router-dom";

import { Tooltip, Button } from "@trace-one/design-system";
import GeneralInformation from "pages/Groups/containers/GroupDetails/GeneralInformation";

import StickyTabContainer from "components/StickyTabContainer";

import GroupModal from "../../../../components/GroupModal";
import Spinner from "../../../../components/Spinner";
import UpdateGroupModal from "../../../../components/UpdateGroupModal";
import { GroupModalMode } from "../../../../shared/constants";
import SharedSubscriptions from "../../containers/GroupDetails/SharedSubscriptions";
import useGroupDetails from "../GroupDetailsPage/hooks/useGroupDetails";

import styles from "./GroupDetailsPage.module.less";

export const GroupDetailsPage: React.FC = () => {
  const { formatMessage } = useIntl();
  const history = useHistory();
  const { groupId } = useParams<{ groupId?: string }>();

  const [isUpdateGroupModalOpen, setIsUpdateGroupModalOpen] = useState(false);

  const [isUpdateGroupModalVisible, setIsUpdateGroupModalVisible] =
    useState<boolean>(false);
  const onModalClose = () => setIsUpdateGroupModalOpen(false);

  const { fetchGroupDetails, isInitiated, groupDetails } = useGroupDetails();

  useEffect(() => {
    fetchGroupDetails(groupId);
  }, [groupId]);

  const tabList = [
    {
      label: formatMessage({ id: "general.generalInformation" }),
      content: <GeneralInformation groupDetails={groupDetails} />,
    },
    {
      label: formatMessage({ id: "groupList.table.sharedSubscription" }),
      content: <SharedSubscriptions groupId={groupId} />,
    },
  ];

  if (!isInitiated) {
    return <Spinner underHeader />;
  }

  function formatGroupName(groupName) {
    if (groupName.length > 80) {
      return `${groupName.substring(0, 80)}...`;
    }
    return groupName;
  }
  const formattedGroupName = formatGroupName(groupDetails?.groupName);

  return (
    <div className={styles.root}>
      <StickyTabContainer
        title={
          <>
            <Tooltip title={groupDetails.groupName} placement="bottom">
              <div
                style={{
                  fontWeight: "bold",
                  fontSize: "30px",
                  marginLeft: "16px",
                  maxWidth: "80ch",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {formattedGroupName}
              </div>
            </Tooltip>
            <Button.Split
              onClick={() => setIsUpdateGroupModalOpen(true)}
              data-test-id="add-new-company-split-button"
              hasItems={true}
              items={[
                {
                  label: formatMessage({ id: "general.edit" }),
                  key: 1,
                  onClick: () => setIsUpdateGroupModalVisible(true),
                },
              ]}
            >
              {formatMessage({ id: "general.addNewCompany" }).toUpperCase()}
            </Button.Split>
          </>
        }
        onBack={() => {
          history.push("/groups/list");
        }}
        tabs={tabList}
      />
      <GroupModal
        mode={GroupModalMode.ADD_TO_GROUP}
        setIsCreateGroupModalOpen={setIsUpdateGroupModalOpen}
        open={isUpdateGroupModalOpen}
        onClose={onModalClose}
        existingGroupName={groupDetails?.groupName}
        existingGroupId={groupId}
        existingGroupCode={groupDetails?.groupCode}
      />

      <UpdateGroupModal
        isModalVisible={isUpdateGroupModalVisible}
        setIsModalVisible={setIsUpdateGroupModalVisible}
        existingGroupName={groupDetails?.groupName}
        existingGroupCode={groupDetails?.groupCode}
        companyGroupId={groupId}
      />
    </div>
  );
};

export default GroupDetailsPage;
