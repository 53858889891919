import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";

import { unwrapResult } from "@reduxjs/toolkit";
import { RoleAndPermissionsForSubscription } from "@trace-one/api-clients.cumd";
import { Tooltip, TagV1 as Tag, toaster } from "@trace-one/design-system";
import { v4 } from "uuid";

import { useAppDispatch } from "reduxStore";
import { selectCompanyActivitiesData } from "reduxStore/shared/selectors";
import { selectUserLanguageCode } from "reduxStore/user/selectors";
import {
  fetchRolesForManageAccess,
  fetchUserForManageAccess,
  fetchResponsibilities,
} from "reduxStore/userAccessManage/asyncActions";
import {
  selectUserAccessManage,
  selectUserRolesForAppsMap,
} from "reduxStore/userAccessManage/selectors";

import ErrorPage from "components/ErrorPage";
import Spinner from "components/Spinner";

import { CumdAPI } from "../../../../apis";
import LeavingFormPrompt from "../../../../components/LeavingFormPrompt";
import StickyTabContainer from "../../../../components/StickyTabContainer";
import usePermissions from "../../../../core/oidc/usePermissions";
import { ErrorCode } from "../../../../shared/errors";
import useResetForm from "../../../../shared/hooks/useResetForm";
import useToast from "../../../../shared/hooks/useToast";
import getFirstErrorMessage from "../../../../shared/utils/getFirstFormErrorMessage";
import UserAccessManage from "../../containers/UserAccessManage";
import { UserAccessManageValues } from "../../containers/UserAccessManage/models";
import getDefaultValues from "../../containers/UserAccessManage/utils/getDefaultValues";
import getSubmitData from "../../containers/UserAccessManage/utils/getSubmitData";
import UserAccessManageGroup from "../../containers/UserAccessManageGroup";
import useGroupDetails from "../../hooks/useGroupDetails";
import useUserStatuses from "../../hooks/useUserStatuses";

import styles from "./UserAccessManagePage.module.less";

type SubscriptionType = {
  subscriptionId?: string;
  functionalId?: string;
  applicationTypeId?: number;
  sharedCompanies?: {
    associatedCompanyId?: string;
    associatedCompanyDisplayName?: string;
    associatedCompanyActivityId?: number;
    sharedByUserId?: string;
    sharedDate?: string;
  }[];
};
export const UserAccessManagePage: React.FC = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();
  const history = useHistory();
  const toast = useToast();
  const { userId } = useParams<{ userId: string }>();
  const languageCode = useSelector(selectUserLanguageCode);
  const { isTraceoneAdmin } = usePermissions();

  const [isInitiated, setIsInitiated] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);
  const [isFormDirty, setIsFormDirty] = useState(false);
  const [error, setError] = useState(false);

  const companyActivities = useSelector(selectCompanyActivitiesData).data;
  const companyActivitiesRef = useRef(companyActivities);
  companyActivitiesRef.current = companyActivities;

  const { userStatusLabels, userStatusTagColors } = useUserStatuses();
  const { userData, rolesData, responsibilitiesData } = useSelector(
    selectUserAccessManage
  );
  const user = userData?.data;
  const rolesMap = useSelector(selectUserRolesForAppsMap);

  const [companyData, setCompanyData] = useState<any>();

  const [sharedSubscriptionInfo, setSharedSubscriptionInfo] = useState<
    SubscriptionType[]
  >([]);
  const [selectedCompanyId, setSelectedCompanyId] = useState<string | null>(
    null
  );
  const [subscriptionRolesAndPermissions, setSubscriptionRolesAndPermissions] =
    useState<RoleAndPermissionsForSubscription[]>();

  const [sharedRoles, setSharedRoles] = useState<any>();
  const initialRoleIds =
    sharedRoles?.flatMap(company => company.roles.map(role => role.roleId)) ||
    [];
  const [roleIds, setRoleIds] = useState<string[]>(initialRoleIds);

  const { fetchGroupDetails, groupDetails } = useGroupDetails();

  // Company Access part

  const defaultValues = getDefaultValues({ user, rolesMap });
  const { handleSubmit, control, formState, reset } =
    useForm<UserAccessManageValues>({
      mode: "onChange",
      reValidateMode: "onChange",
      defaultValues,
    });
  const resetFormIdRef = useResetForm({ reset, defaultValues });
  const refetchUser = () => {
    return dispatch(fetchUserForManageAccess({ userId }));
  };

  const refetchRoles = ({ companyId }: { companyId: string }) => {
    return dispatch(
      fetchRolesForManageAccess({
        companyId,
        languageCode,
      })
    );
  };
  const refetchResponsibilities = ({
    companyActivityGuid,
    companyId,
  }: {
    companyActivityGuid: string;
    companyId: string;
  }) => {
    return dispatch(
      fetchResponsibilities({
        parentItemId: companyActivityGuid,
        languageCode,
        companyId,
      })
    );
  };

  useEffect(() => {
    refetchUser()
      .then(unwrapResult)
      .then(({ user: { owningCompanyId }, companyActivityGuid }) =>
        Promise.all([
          refetchRoles({ companyId: owningCompanyId }),
          refetchResponsibilities({
            companyActivityGuid,
            companyId: owningCompanyId,
          }),
        ])
      )
      .finally(() => {
        setIsInitiated(true);
      });
  }, [userId]);

  useEffect(() => {
    if (user && rolesMap) {
      const newDefaultValues = getDefaultValues({ user, rolesMap });
      reset(newDefaultValues); // Reset the form with the new values
    }
  }, [user, rolesMap, reset]);

  useEffect(() => {
    if (isInitiated) {
      return;
    }
    const companyId = userData.data?.owningCompanyId;
    const companyActivityGuid = userData.companyActivityGuid;

    if (companyId) {
      refetchRoles({ companyId });
    }
    if (companyActivityGuid) {
      refetchResponsibilities({
        companyActivityGuid,
        companyId,
      });
    }
  }, [languageCode]);

  const onSubmitCompanyAccess = async (values: UserAccessManageValues) => {
    const data = getSubmitData({ values });

    try {
      await CumdAPI.updateUserByAdmin(user.userId, data);
      await refetchUser();
      toast.saveSuccess();
      resetFormIdRef.current = v4();
    } catch (error) {
      error.response.data.errorCode === ErrorCode.REACHED_MAX_NUMBER_OF_USERS
        ? toaster.open({
            message: formatMessage({
              id: "toast.alert",
            }),
            description: formatMessage({
              id: "toast.error.max.number.users.reached",
            }),
            type: "alert",
          })
        : toast.saveError({ error });
    }
  };

  // Group Access Part

  useEffect(() => {
    if (companyData?.companyGroupId !== null) {
      fetchGroupDetails(companyData?.companyGroupId);
    } else {
      return;
    }
  }, [companyData?.companyGroupId]);
  const fetchCompanyData = async companyId => {
    try {
      const { data: company } = await CumdAPI.getCompanyById(companyId);
      setCompanyData(company);
    } catch (error) {}
  };

  useEffect(() => {
    if (user?.owningCompanyId) {
      fetchCompanyData(user?.owningCompanyId);
    }
  }, [user?.owningCompanyId]);

  const foundSubscriptionId =
    groupDetails?.companies?.find(
      company => company.companyId === selectedCompanyId
    )?.sharedSubscriptions?.[0]?.subscriptionId || null;

  const onSubmitGroupAccess = async () => {
    try {
      await CumdAPI.shareRolesToUser(user?.userId, {
        sharedSubscriptionId: foundSubscriptionId,
        roleIds: roleIds,
      });
      toast.saveSuccess();
      setIsFormDirty(false);
      setTimeout(function () {
        window.location.reload();
      }, 500);
    } catch (error) {
      if (
        error.response.data.errorCode === ErrorCode.ROLE_IDS_CANNOT_BE_EMPTY
      ) {
        setError(true);
        toaster.open({
          message: formatMessage({
            id: "toast.alert",
          }),
          description: formatMessage({
            id: "userAccessManage.applications.emptyRoles",
          }),
          type: "alert",
        });
      } else {
        toast.saveError({ error });
      }
    }
  };

  const tabList = [
    {
      label: formatMessage({ id: "general.companyAccess" }),
      content: (
        <UserAccessManage
          refetchUser={refetchUser}
          user={userData.data}
          teamMemberResponsibilities={responsibilitiesData?.data}
          control={control}
          formState={formState}
          resetFormIdRef={resetFormIdRef}
        />
      ),
    },
    companyData?.companyGroupId !== null &&
      isTraceoneAdmin && {
        label: formatMessage({ id: "general.groupAccess" }),
        content: (
          <UserAccessManageGroup
            user={userData.data}
            groupDetails={groupDetails}
            currentCompanyId={userData?.data?.owningCompanyId}
            setRoleIds={setRoleIds}
            roleIds={roleIds}
            sharedSubscriptionInfo={sharedSubscriptionInfo}
            setSharedSubscriptionInfo={setSharedSubscriptionInfo}
            setSelectedCompanyId={setSelectedCompanyId}
            selectedCompanyId={selectedCompanyId}
            sharedRoles={sharedRoles}
            setSharedRoles={setSharedRoles}
            subscriptionRolesAndPermissions={subscriptionRolesAndPermissions}
            setSubscriptionRolesAndPermissions={
              setSubscriptionRolesAndPermissions
            }
            setIsFormDirty={setIsFormDirty}
            error={error}
            setError={setError}
            isFormDirty={isFormDirty}
            foundSubscriptionId={foundSubscriptionId}
          />
        ),
      },
  ];

  const headerTitle = (
    <div className={styles.headerContainer}>
      <Tag
        label={
          userData?.data?.isMainContact === true
            ? formatMessage({
                id: "general.isMainContact",
              })
            : userStatusLabels[userData?.data?.userStatus]
        }
        color={userStatusTagColors[userData?.data?.userStatus]}
        size="medium"
        mode="light"
      />
      <Tooltip
        placement="bottom"
        text={`${userData?.data?.userFirstName} ${userData?.data?.userLastName}`}
        showFullText
      >
        <div
          style={{
            fontWeight: "bold",
            fontSize: "30px",
            textTransform: "uppercase",
            marginLeft: "16px",
            maxWidth: "55ch",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {userData?.data?.userFirstName} {userData?.data?.userLastName}{" "}
        </div>
      </Tooltip>
    </div>
  );

  if (
    userData.hasError ||
    rolesData.hasError ||
    responsibilitiesData.hasError
  ) {
    return (
      <ErrorPage
        status={
          userData.errorStatus ||
          rolesData.errorStatus ||
          responsibilitiesData.errorStatus
        }
      />
    );
  }

  if (!isInitiated) {
    return <Spinner underHeader />;
  }

  const handleFormSubmit = (values: any) => {
    if (selectedTab === 0) {
      return onSubmitCompanyAccess(values);
    } else {
      return onSubmitGroupAccess();
    }
  };

  const handleFormErrors = (errors: any) => {
    const firstErrorMessage = getFirstErrorMessage(errors);
    toast.error({
      checkErrorResponse: false,
      description: firstErrorMessage,
    });
  };

  return (
    <form onSubmit={handleSubmit(handleFormSubmit, handleFormErrors)}>
      <StickyTabContainer
        title={headerTitle}
        description={formatMessage({ id: "userAccessManage.description" })}
        onBack={() => {
          history.push("/users/list");
        }}
        tabs={tabList}
        mode="edit"
        isSubmitting={formState.isSubmitting}
        onCancel={() => {
          history.push("/users/list");
        }}
        selectedTab={selectedTab}
        onTabChange={index => setSelectedTab(index)}
        isFormDirty={isFormDirty}
        setIsFormDirty={setIsFormDirty}
      />
      <LeavingFormPrompt when={isFormDirty} />
    </form>
  );
};

export default UserAccessManagePage;
