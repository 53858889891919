import React from "react";
import classNames from "classnames";
import { Divider, Spinner } from "@trace-one/design-system";
import { withLanguageData } from "IndexComponents";
import AppList from "./components/AppList";
import IntercomAppsList from "./components/IntercomAppsList";
import DiscoverSolutions from "./components/DiscoverSolutions";
import { useAppSwitcher } from "./hooks";
import { AppsListContext } from "./contexts";
import { AppSwitcherProps, AppSwitcherVariant } from "./types";
import { getPrefixClassName, isListEmpty } from "src/utils/general";
import "./styles.less";

import frFr from "./translations/fr-FR.json";
import enUs from "./translations/en-US.json";
import deDe from "./translations/de-DE.json";
import esEs from "./translations/es-ES.json";
import ptPt from "./translations/pt-PT.json";
import itIt from "./translations/it-IT.json";
import nlBe from "./translations/nl-BE.json";

const DEFAULT_LANG = { ...enUs };
const enhance = withLanguageData({
  translations: {
    "en-US": DEFAULT_LANG,
    "fr-FR": { ...DEFAULT_LANG, ...frFr },
    "de-DE": { ...DEFAULT_LANG, ...deDe },
    "es-ES": { ...DEFAULT_LANG, ...esEs },
    "pt-PT": { ...DEFAULT_LANG, ...ptPt },
    "it-IT": { ...DEFAULT_LANG, ...itIt },
    "nl-BE": { ...DEFAULT_LANG, ...nlBe },
  },
});

const AppSwitcher: React.FC<AppSwitcherProps> = ({
  excludeApps = [],
  accessToken,
  showDiscoverSolutions,
  withIntercomTrack,
  handleClose,
  userId,
  handleCreationPopUp = ({ ...props }) => false,
  fetchCompaniesByIds,
  variant,
  includeApps = [],
  orderAppsById = false,
}) => {
  const { apps, loading } = useAppSwitcher({
    fetchCompaniesByIds,
    excludeApps,
    includeApps,
    orderAppsById,
    variant,
  });

  const prefixClassName = getPrefixClassName("app-switcher");

  if (loading) {
    return <Spinner />;
  }

  return (
    <div className={classNames(`${prefixClassName}`, variant ?? "")}>
      <div
        className={`${prefixClassName}-list`}
        data-test-id={`${prefixClassName}-list`}
      >
        <AppsListContext.Provider
          value={{
            appList: apps,
            accessToken,
            userId,
            prefixClassName,
          }}
        >
          {!withIntercomTrack
            ? (
            <AppList
              handleCreationPopUp={handleCreationPopUp}
              collapsed={!showDiscoverSolutions}
              variant={variant}
            />
              )
            : (
            <IntercomAppsList
              handleCreationPopUp={handleCreationPopUp}
              variant={variant}
            />
              )}
        </AppsListContext.Provider>
      </div>
      {showDiscoverSolutions && (
        <>
          {!isListEmpty(apps) && variant !== AppSwitcherVariant.VERTICAL && (
            <Divider dashed={true} height={16} color="grey-2" />
          )}
          <DiscoverSolutions
            handleClose={handleClose}
            userId={userId}
            variant={variant}
          />
        </>
      )}
    </div>
  );
};

export default enhance(AppSwitcher);
