import React from "react";
import { NotificationCard, toaster } from "@trace-one/design-system";

import "../../styles.less";
import { useIntl } from "react-intl";
import moment from "moment";
import {
  cardTime,
  notificationActionTitles,
} from "src/components/NotificationsPanel/translations/defineMessage";
import { NotificationCardItemProps } from "src/components/NotificationsPanel/types";
import { updateNotificationStatus } from "src/apis/notif";
import { NotificationStatus } from "src/models/notification";
import NotificationActionItem from "./NotificationActionItem";

const NotificationCardItem: React.FC<NotificationCardItemProps> = ({
  notification,
  toggleNotificationsReadStatus,
  deactivateNotificationsType,
  removeNotifications,
}) => {
  const { formatMessage } = useIntl();
  const isNotificationRead = notification.status === NotificationStatus.READ;
  const isOlderThan10Days =
    moment().diff(moment(notification.notificationEventUtcDateTime), "days") >
    10;

  const getRightDate = (date: Date | string | number) => {
    const dateMoment = moment(date);
    const minDiff = moment().diff(dateMoment, "minutes");
    if (minDiff < 1) {
      return formatMessage(cardTime.momentAgo);
    } else if (minDiff <= 60) {
      return formatMessage(cardTime.minutesAgo, {
        minutes: minDiff,
        plural: minDiff > 1 ? "s" : "",
      });
    } else if (minDiff < 60 * 24) {
      const hourDiff = moment().diff(dateMoment, "hours");
      return formatMessage(cardTime.hoursAgo, {
        hours: hourDiff,
        plural: hourDiff > 1 ? "s" : "",
      });
    } else {
      return dateMoment.format("DD/MM/YYYY");
    }
  };

  const openLink = async (id: string, redirectURL: string, isRead: boolean) => {
    if (!isRead) {
      await markNotificationAsRead(id);
    }

    if (redirectURL) {
      window.open(redirectURL, "_self");
    }
  };

  const markNotificationAsRead = async (id: string) => {
    try {
      await updateNotificationStatus([id], NotificationStatus.READ);
      toggleNotificationsReadStatus([id], NotificationStatus.READ);
    } catch (error) {
      console.error(error);
    }
  };

  const markNotificationAsUnread = async (id: string) => {
    try {
      await updateNotificationStatus([id], NotificationStatus.UNREAD);
      toggleNotificationsReadStatus([id], NotificationStatus.UNREAD);
    } catch (error) {
      console.error(error);
    }
  };

  const handleDeactivate = () => {
    deactivateNotificationsType(notification.notificationTypeId);
    toaster.open({
      description: "The notification has been deactivated",
    });
  };

  const handleMarkAsRead = () => {
    markNotificationAsRead(notification.occurrenceUserInstanceId);
  };

  const handleMarkAsUnread = () => {
    markNotificationAsUnread(notification.occurrenceUserInstanceId);
  };

  const handleDelete = () => {
    removeNotifications([notification.occurrenceUserInstanceId]);
    toaster.open({
      description: "The notification has been deleted",
    });
  };

  const menuItems = [];

  if (notification.isUserAllowedToSetPreferences) {
    menuItems.push({
      label: (
        <NotificationActionItem
          title={formatMessage(notificationActionTitles.deactivateNotification)}
          description={formatMessage(
            notificationActionTitles.deactivationDescription
          )}
        />
      ),
      key: "deactivate-notification",
      onClick: handleDeactivate,
    });
  }

  if (isOlderThan10Days) {
    menuItems.push({
      label: (
        <NotificationActionItem
          title={formatMessage(notificationActionTitles.deleteNotification)}
          description={formatMessage(
            notificationActionTitles.deleteDescription
          )}
        />
      ),
      key: "delete-notification",
      onClick: handleDelete,
    });
  }

  if (isNotificationRead) {
    menuItems.push({
      label: (
        <NotificationActionItem
          title={formatMessage(notificationActionTitles.markAsUnread)}
        />
      ),
      key: "mark-notification-as-unread",
      onClick: handleMarkAsUnread,
    });
  } else {
    menuItems.push({
      label: (
        <NotificationActionItem
          title={formatMessage(notificationActionTitles.markAsRead)}
        />
      ),
      key: "mark-notification-as-read",
      onClick: handleMarkAsRead,
    });
  }

  let app = notification.senderZone.toLowerCase();
  if (app === "quality portal") {
    app = "qp";
  }
  if (app === "pack portal") {
    app = "pp";
  }

  return (
    <NotificationCard
      dataTestId={notification.notificationTypeTitle}
      app={app}
      tag={isNotificationRead ? "" : "New"}
      tagColor="blue"
      title={notification.notificationSubject}
      body={notification.notificationContent}
      timestamp={notification.notificationEventUtcDateTime}
      formatedTimestamp={getRightDate(
        new Date(notification.notificationEventUtcDateTime)
      )}
      actions={menuItems}
      onClick={() => {
        openLink(
          notification.occurrenceUserInstanceId,
          notification.notificationUrl,
          notification.status === NotificationStatus.READ
        );
      }}
      // eslint-disable-next-line no-undef
      onBodyClick={async (e: React.MouseEvent<HTMLElement>) => {
        // eslint-disable-next-line no-undef
        if (e.target instanceof HTMLAnchorElement) {
          e.preventDefault();
          await markNotificationAsRead(notification.occurrenceUserInstanceId);
          window.location.href = e.target.href;
        }
      }}
    />
  );
};

export default NotificationCardItem;
