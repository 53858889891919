import React, { useEffect } from "react";
import { useIntl } from "react-intl";

import { UserForUIAdmin } from "@trace-one/api-clients.cumd";
import { Typography } from "@trace-one/design-system";
import { Table } from "@trace-one/react-components";

import { CumdAPI } from "apis";

import styles from "../../AppsAndPermissionsSection.module.less";

import RolesSelect from "./RolesSelect";
import usePermissionsTable from "./usePermissionsTable";

interface PermissionsProps {
  selectedApp?: number;
  sharedSubscriptionInfo?: any;
  user?: UserForUIAdmin;
  selectedCompanyId?: string;
  setRoleIds?: any;
  roleIds?: string[];
  setSharedRoles?: any;
  sharedRoles?: any;
  subscriptionRolesAndPermissions?: any;
  setSubscriptionRolesAndPermissions?: any;
  setIsFormDirty?: any;
  error?: boolean;
  setError?: any;
}

const Permissions: React.FC<PermissionsProps> = ({
  selectedApp,
  sharedSubscriptionInfo,
  user,
  selectedCompanyId,
  setRoleIds,
  roleIds,
  setSharedRoles,
  sharedRoles,
  subscriptionRolesAndPermissions,
  setSubscriptionRolesAndPermissions,
  setIsFormDirty,
  error,
  setError,
}) => {
  const { formatMessage } = useIntl();

  const foundSubscriptionId = sharedSubscriptionInfo?.find(
    subscription => subscription.applicationTypeId === selectedApp
  )?.subscriptionId;

  const fetchRolesAndPermissionsForSubscription = async () => {
    const { data } = await CumdAPI.getRolesAndPermissionsForSubscription(
      foundSubscriptionId,
      { includeOnlySharableRoles: true }
    );
    setSubscriptionRolesAndPermissions(data);
  };

  useEffect(() => {
    fetchRolesAndPermissionsForSubscription();
  }, [selectedApp]);

  const { columns, data } = usePermissionsTable(
    sharedRoles,
    subscriptionRolesAndPermissions
  );
  const tableWidth = (columns.length - 1) * 250;

  const fetchSharedRolesForUser = async () => {
    const { data } = await CumdAPI.searchSharedRolesByUserId(user?.userId, {
      companyId: selectedCompanyId,
    });

    setSharedRoles(data);
  };

  useEffect(() => {
    fetchSharedRolesForUser();
  }, []);

  return (
    <div
      className={styles.permissionsContainer}
      data-test-id="ad-user-manage-group-access-permissions-and-roles"
    >
      <Typography>
        {formatMessage({
          id: "userAccessManage.roles.description",
        })}
      </Typography>
      <RolesSelect
        subscriptionRolesAndPermissions={subscriptionRolesAndPermissions}
        setRoleIds={setRoleIds}
        roleIds={roleIds}
        sharedRoles={sharedRoles}
        setIsFormDirty={setIsFormDirty}
        error={error}
        setError={setError}
      />
      <Table
        className={styles.root}
        columns={columns}
        dataSource={data}
        scroll={{ x: tableWidth }}
        pagination={false}
      />
    </div>
  );
};

export default Permissions;
