import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useIntl } from "react-intl";

import {
  RoleAndPermissionsForSubscription,
  UserForUIAdmin,
} from "@trace-one/api-clients.cumd";
import {
  Typography,
  Heading,
  Divider,
  Illustration,
} from "@trace-one/design-system";

import { CumdAPI } from "../../../../../apis";

import styles from "./AppsAndPermissionsSection.module.less";
import Apps from "./componnets/Apps";
import Permissions from "./componnets/Permissions";
import PermissionsNoFound from "./componnets/Permissions/PermissionsNoFound";

interface AppsAndPermissionsSectionProps {
  user: UserForUIAdmin;
  selectedCompanyId?: string;
  selectedCompanyName?: string;
  sharedSubscriptionInfo?: any;
  setRoleIds?: any;
  roleIds?: string[];
  setSharedRoles?: any;
  sharedRoles?: any;
  subscriptionRolesAndPermissions?: RoleAndPermissionsForSubscription[];
  setSubscriptionRolesAndPermissions?: any;
  setIsFormDirty?: any;
  isFormDirty?: boolean;
  setError?: any;
  error?: boolean;
  foundSubscriptionId?: string;
  selectedApp: number;
  setSelectedApp: Dispatch<SetStateAction<number>>;
}
const AppsAndPermissionsSection: React.FC<AppsAndPermissionsSectionProps> = ({
  user,
  selectedCompanyId,
  selectedCompanyName,
  sharedSubscriptionInfo,
  setRoleIds,
  roleIds,
  setSharedRoles,
  sharedRoles,
  subscriptionRolesAndPermissions,
  setSubscriptionRolesAndPermissions,
  setIsFormDirty,
  isFormDirty,
  setError,
  error,
  foundSubscriptionId,
  selectedApp,
  setSelectedApp,
}) => {
  const { formatMessage } = useIntl();
  const [roles, setRoles] = useState<any>();

  useEffect(() => {
    const applicationTypeIds = sharedSubscriptionInfo.map(
      subscription => subscription.applicationTypeId
    );

    const fetchData = async () => {
      await fetchRoles(applicationTypeIds);
    };

    fetchData();
  }, [sharedSubscriptionInfo]);

  const fetchRoles = async appIds => {
    const roles = await Promise.all(
      appIds.map(async applicationTypeId => {
        const response = await CumdAPI.searchSharedRolesByUserId(user?.userId, {
          companyId: selectedCompanyId,
          applicationTypeId: applicationTypeId,
        });
        // @ts-ignore
        if (response.data.length !== 0) {
          return { applicationTypeId, roles: response.data[0].roles };
        }
      })
    );

    setRoles(roles.filter(Boolean));
  };

  return (
    <div className={styles.container}>
      <Heading size="s">
        {formatMessage({
          id: "general.applications",
        })}
      </Heading>
      <Typography>
        {formatMessage({
          id: "userAccessManage.applications.description",
        })}
      </Typography>
      {selectedCompanyName !== undefined ? (
        <>
          <Apps
            selectedCompanyName={selectedCompanyName}
            sharedSubscriptionInfo={sharedSubscriptionInfo}
            selectedApp={selectedApp}
            setSelectedApp={setSelectedApp}
            setIsFormDirty={setIsFormDirty}
            roles={roles}
            setRoleIds={setRoleIds}
            isFormDirty={isFormDirty}
            setError={setError}
            roleIds={roleIds}
            foundSubscriptionId={foundSubscriptionId}
            user={user}
          />
          <Divider color="grey-3" dashed />{" "}
          <Heading size="xs">
            {formatMessage({ id: "general.permissionsByRole" })}
          </Heading>
          {selectedApp !== null ? (
            <Permissions
              selectedApp={selectedApp}
              sharedSubscriptionInfo={sharedSubscriptionInfo}
              selectedCompanyId={selectedCompanyId}
              user={user}
              setRoleIds={setRoleIds}
              roleIds={roleIds}
              sharedRoles={sharedRoles}
              setSharedRoles={setSharedRoles}
              subscriptionRolesAndPermissions={subscriptionRolesAndPermissions}
              setSubscriptionRolesAndPermissions={
                setSubscriptionRolesAndPermissions
              }
              setIsFormDirty={setIsFormDirty}
              error={error}
              setError={setError}
            />
          ) : (
            <PermissionsNoFound />
          )}
        </>
      ) : (
        <div
          className={styles.generalEmpty}
          data-test-id="ad-user-manage-group-access-apps-and-permissions-empty-state"
        >
          <div>
            <Illustration name="no-data" size={120} color="grey" />
          </div>
          <Typography>
            {formatMessage({
              id: "userAccessManage.groupAccess.appsAndPermissions.noFound.text",
            })}
          </Typography>
        </div>
      )}
    </div>
  );
};

export default AppsAndPermissionsSection;
