import React, { useEffect, useState } from "react";

import {
  RoleAndPermissionsForSubscription,
  UserForUIAdmin,
} from "@trace-one/api-clients.cumd";
import { GroupDetails } from "@trace-one/api-clients.cumd/dist/models/group";

import AppsAndPermissionsSection from "./AppsAndPermissionsSection";
import CompanySelectSection from "./CompanySelectSection";
import GroupInfoSection from "./GroupInfoSection";
import styles from "./UserAccessManageGroup.module.less";

interface UserAccessManageGroupProps {
  user: UserForUIAdmin;
  groupDetails?: GroupDetails;
  currentCompanyId?: string;
  setRoleIds?: any;
  roleIds?: string[];
  sharedSubscriptionInfo?: any;
  setSharedSubscriptionInfo?: any;
  selectedCompanyId?: string;
  setSelectedCompanyId?: any;
  setSharedRoles?: any;
  sharedRoles?: any;
  subscriptionRolesAndPermissions?: RoleAndPermissionsForSubscription[];
  setSubscriptionRolesAndPermissions?: any;
  setIsFormDirty?: any;
  setError?: any;
  error?: boolean;
  isFormDirty?: boolean;
  foundSubscriptionId?: string;
}
const UserAccessManageGroup: React.FC<UserAccessManageGroupProps> = ({
  user,
  groupDetails,
  currentCompanyId,
  setRoleIds,
  roleIds,
  sharedSubscriptionInfo,
  setSharedSubscriptionInfo,
  selectedCompanyId,
  setSelectedCompanyId,
  setSharedRoles,
  sharedRoles,
  subscriptionRolesAndPermissions,
  setSubscriptionRolesAndPermissions,
  setIsFormDirty,
  setError,
  error,
  isFormDirty,
  foundSubscriptionId,
}) => {
  const [selectedCompanyName, setSelectedCompanyName] = useState();
  const [selectedApp, setSelectedApp] = useState<number>(null);

  useEffect(() => {
    if (!selectedCompanyId || !currentCompanyId) return;

    const selectedCompany = groupDetails?.companies?.find(
      company => company.companyId === selectedCompanyId
    );

    if (selectedCompany && selectedCompany.sharedSubscriptions) {
      const matchingSubscriptions = selectedCompany?.sharedSubscriptions.filter(
        subscription =>
          subscription.sharedCompanies?.some(
            sharedCompany =>
              sharedCompany.associatedCompanyId === currentCompanyId
          )
      );

      setSharedSubscriptionInfo(matchingSubscriptions);
    }
  }, [groupDetails, selectedCompanyId, currentCompanyId]);

  return (
    <div className={styles.container}>
      <div className={styles.sectionContainer}>
        <GroupInfoSection groupDetails={groupDetails} />
      </div>
      <div className={styles.sectionContainer}>
        <CompanySelectSection
          groupDetails={groupDetails}
          currentCompanyId={currentCompanyId}
          setSelectedCompanyId={setSelectedCompanyId}
          setSelectedCompanyName={setSelectedCompanyName}
          isFormDirty={isFormDirty}
          setIsFormDirty={setIsFormDirty}
          setError={setError}
          user={user}
          foundSubscriptionId={foundSubscriptionId}
          roleIds={roleIds}
          setSelectedApp={setSelectedApp}
        />
      </div>
      <div className={styles.sectionContainer}>
        <AppsAndPermissionsSection
          user={user}
          selectedCompanyId={selectedCompanyId}
          selectedCompanyName={
            selectedCompanyName && selectedCompanyName?.["name"]
          }
          sharedSubscriptionInfo={sharedSubscriptionInfo}
          setRoleIds={setRoleIds}
          roleIds={roleIds}
          sharedRoles={sharedRoles}
          setSharedRoles={setSharedRoles}
          subscriptionRolesAndPermissions={subscriptionRolesAndPermissions}
          setSubscriptionRolesAndPermissions={
            setSubscriptionRolesAndPermissions
          }
          setIsFormDirty={setIsFormDirty}
          isFormDirty={isFormDirty}
          error={error}
          setError={setError}
          foundSubscriptionId={foundSubscriptionId}
          selectedApp={selectedApp}
          setSelectedApp={setSelectedApp}
        />
      </div>
    </div>
  );
};

export default UserAccessManageGroup;
