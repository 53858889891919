import React from "react";
import { Modal } from "@trace-one/design-system";
import { FormattedMessage } from "react-intl";
import { withLanguageData } from "IndexComponents";
import AllergensList from "./components/AllergensList";
import { allergensModalMessages } from "./translations/messages";
import { useModalVisibility } from "src/hooks";
import { AllergensModalProps } from "./types";
import { getPrefixClassName, isListEmpty } from "src/utils/general";
import "./styles.less";

import frFr from "./translations/fr-FR.json";
import enUs from "./translations/en-US.json";
import deDe from "./translations/de-DE.json";
import esEs from "./translations/es-ES.json";
import ptPt from "./translations/pt-PT.json";
import itIt from "./translations/it-IT.json";
import nlBe from "./translations/nl-BE.json";
import SummaryList from "./components/SummaryList";
import useAllergens from "./hooks/useAllergens";

const DEFAULT_LANG = { ...enUs };
const enhance = withLanguageData({
  translations: {
    "en-US": DEFAULT_LANG,
    "fr-FR": { ...DEFAULT_LANG, ...frFr },
    "de-DE": { ...DEFAULT_LANG, ...deDe },
    "es-ES": { ...DEFAULT_LANG, ...esEs },
    "pt-PT": { ...DEFAULT_LANG, ...ptPt },
    "it-IT": { ...DEFAULT_LANG, ...itIt },
    "nl-BE": { ...DEFAULT_LANG, ...nlBe },
  },
});

const AllergensModal: React.FC<AllergensModalProps> = ({ onClose, open, subtitle, selectedAllergens: selectedAllergensProp = [], onSubmit }) => {
  const { onCloseModal, isModalOpen } = useModalVisibility({
    defaultVisibility: open,
    onClose,
  });

  const prefixClassName = getPrefixClassName("allergens-modal");
  const { isLoading, selectedAllergens, allergensData, setSelectedAllergens } = useAllergens({ selectedAllergensProp });

  const onAddAllergen = () => {
    const allergenIds = selectedAllergens.filter(allergen => {
      const allergenData = allergensData?.find(({ id }) => id === allergen.id);
      if (allergen.children.length === allergenData.children.length) {
        return true;
      }
      return false;
    }).map(a => a.id);
    const childrenIds = selectedAllergens
      .filter(allergen => !allergenIds.includes(allergen.id))
      .flatMap(allergen => allergen.children)
      .map(child => child.id);
    onSubmit({
      selectedIds: allergenIds.concat(childrenIds),
      selectedData: selectedAllergens,
    });
  };

  return (
    <Modal.Double
      title={<FormattedMessage {...allergensModalMessages.title} />}
      size="xl"
      className={prefixClassName}
      open={isModalOpen}
      onCancel={onCloseModal}
      subtitleRight={<FormattedMessage {...allergensModalMessages.summary} />}
      primaryButtonProps={{
        disabled: isListEmpty(allergensData) || isListEmpty(selectedAllergens),
      }}
      primaryButtonText={<FormattedMessage {...allergensModalMessages.addAllergen} />}
      onPrimaryButtonClick={onAddAllergen}
      leftContent={<AllergensList subtitle ={subtitle} allergensData={allergensData} selectedAllergens={selectedAllergens} setSelectedAllergens={setSelectedAllergens} isLoading={isLoading} />}
      rightContent={<SummaryList allergensData={allergensData} selectedAllergens={selectedAllergens} setSelectedAllergens={setSelectedAllergens} isLoading={isLoading} />}
    ></Modal.Double>
  );
};

export default enhance(AllergensModal);
