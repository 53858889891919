import { useState, useRef } from "react";

import { GroupDetails } from "@trace-one/api-clients.cumd/dist/models/group";

import { CumdAPI } from "apis";

export default function useAllGroupDetails() {
  const [isDetailsLoading, setIsDetailsLoading] = useState(false);
  const [isInitialazed, setIsInitialazed] = useState(false);
  const detailsInfoRef = useRef<GroupDetails | null>(null);

  const fetchAllGroupDetails = async (id: string) => {
    setIsDetailsLoading(true);

    if (id !== undefined) {
      try {
        const { data } = await CumdAPI.getCompanyGroupDetails(id);
        detailsInfoRef.current = data;
        setIsDetailsLoading(false);
        setIsInitialazed(true);
      } catch (error) {
        console.log(error);
      }
    }
  };

  return {
    fetchAllGroupDetails,
    isDetailsLoading,
    isInitialazed,
    groupAllDetails: detailsInfoRef.current,
  };
}
