import { useIntl } from "react-intl";

import styles from "../../../Users/containers/TraceoneAdminUserList/TraceoneAdminUserList.module.less";

interface GroupHeaderProps {
  totalCount?: number;
  currentNumber?: number;
}

const GroupTableHeader: React.FC<GroupHeaderProps> = ({ currentNumber }) => {
  const { formatMessage } = useIntl();

  return (
    <>
      <div className={styles.switch}>
        <div className={styles.userlistNumber}>
          <span className={styles.productsListNumberCount}>
            {formatMessage(
              {
                id: "companiesList.tableHeader.listingResultNumber",
              },
              {
                current: currentNumber,
              }
            )}
          </span>
        </div>
      </div>
    </>
  );
};

export default GroupTableHeader;
