import { useCallback, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";

import { Button, Heading, toaster } from "@trace-one/design-system";

import { CumdAPI, RlmdAPI } from "../../apis";
import { GroupCompaniesFilter } from "../../pages/Groups/models";
import { useAppDispatch } from "../../reduxStore";
import { clearCompanies } from "../../reduxStore/companyList/slice";
import { fetchGroupCompanies } from "../../reduxStore/groupCompanyList/asyncActions";
import {
  selectGroupCompanies,
  selectGroupCompaniesSkipAndTakeQueryStats,
} from "../../reduxStore/groupCompanyList/selectors";
import { fetchCountries } from "../../reduxStore/shared/asyncActions";
import { selectCountriesData } from "../../reduxStore/shared/selectors";
import { selectUserLanguageCode } from "../../reduxStore/user/selectors";
import { CompanyStatus, GroupModalMode } from "../../shared/constants";
import useSearchFilters from "../../shared/hooks/useSearchFilters";
import useTablePagination from "../../shared/hooks/useTablePagination";
import useToast from "../../shared/hooks/useToast";
import {
  SearchFiltersStorage,
  TablePaginationStorage,
} from "../../shared/webStorage";

import Basket from "./components/Basket";
import CompaniesList from "./components/CompaniesList";
import GroupSearch from "./components/GroupSearch";
import styles from "./GroupModal.module.less";

export interface GroupModalProps {
  mode?: string;
  open?: boolean;
  onClose?: () => void;
  setIsCreateGroupModalOpen: (boolean) => void;
  existingGroupName?: string;
  existingGroupId?: string;
  existingGroupCode?: string;
}
const GroupModal: React.FC<GroupModalProps> = ({
  open,
  onClose,
  mode,
  setIsCreateGroupModalOpen,
  existingGroupName,
  existingGroupId,
  existingGroupCode,
}) => {
  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();
  const languageCode = useSelector(selectUserLanguageCode);
  const toast = useToast();

  const companies = useSelector(selectGroupCompanies);
  const [companiesInBasket, setCompaniesInBasket] = useState([]);
  const [groupName, setGroupName] = useState<string>();
  const [groupCode, setGroupCode] = useState<string>();

  const [groupNameError, setGroupNameError] = useState<boolean>(false);

  const countries = useSelector(selectCountriesData);
  const [companyActivities, setCompanyActivities] = useState<any>();

  const skipAndTakeQueryStats = useSelector(
    selectGroupCompaniesSkipAndTakeQueryStats
  );

  const { paginationQuery, setPaginationQuery } = useTablePagination({
    skipAndTakeQueryStats: skipAndTakeQueryStats,
    webStorage: TablePaginationStorage.GROUP_CREATE_COMPANY_LIST,
  });

  const {
    searchText,
    setSearchText,
    filterObj,
    mergeFilters,
    removeAllFiltersAndResetPageNumber,
  } = useSearchFilters<GroupCompaniesFilter>(
    {
      companyActivityId: undefined,
      companyStatuses: [CompanyStatus.ENABLED],
      companyCountry: undefined,
    },
    {
      clearedFilters: {
        companyActivityId: undefined,
        companyStatuses: [],
        companyCountry: undefined,
      },
      webStorage: SearchFiltersStorage.GROUP_CREATE_COMPANY_LIST,
    }
  );
  const { companyActivityId, companyCountry, companyStatuses } = filterObj;

  const refetchCompanies = useCallback(() => {
    const companyActivityIdNumber = companyActivityId;

    dispatch(
      fetchGroupCompanies({
        companyActivityId: companyActivityId
          ? Number(companyActivityIdNumber)
          : undefined,
        companyStatuses: [CompanyStatus.ENABLED],
        companyCountry: companyCountry,
        searchText: searchText,
        skip: paginationQuery.skip,
        take: paginationQuery.take,
      })
    );
  }, [
    searchText,
    paginationQuery,
    companyStatuses,
    companyCountry,
    companyActivityId,
  ]);

  useEffect(() => {
    return () => {
      dispatch(clearCompanies(null));
    };
  }, []);

  useEffect(() => {
    refetchCompanies();
  }, [refetchCompanies]);

  useEffect(() => {
    dispatch(
      fetchCountries({
        languageCode,
      })
    );
  }, []);

  const countriesOptions = countries.data?.map(country => ({
    value: country.code,
    label: country.name,
  }));
  const sortedCountriesOptions = countriesOptions?.sort((a, b) =>
    a.label.localeCompare(b.label)
  );

  const fetchCompanyActivities = async () => {
    try {
      const { data: companyActivities } =
        await RlmdAPI.getReferenceListItemsByReferenceListName(
          "Company_Activity",
          {
            languageCode,
          }
        );
      setCompanyActivities(companyActivities);
    } catch (error) {
      toast.saveError({ error });
    }
  };

  useEffect(() => {
    fetchCompanyActivities();
  }, []);

  const createGroup = async () => {
    const companyIds = companiesInBasket?.map(company => company.companyId);

    try {
      await CumdAPI.createGroup({
        groupName: groupName,
        groupCode: groupCode,
        companyIds: companyIds,
      });

      toaster.open({
        message: formatMessage({
          id: "toast.confirmation",
        }),
        description: formatMessage({
          id: "groupCreate.toaster.success",
        }),
        type: "confirmation",
      });

      setIsCreateGroupModalOpen(false);

      setTimeout(function () {
        window.location.reload();
      }, 800);
    } catch (error) {
      if (!groupName) {
        setGroupNameError(true);
        toast.saveError({ error });

        return;
      } else {
        toast.saveError({ error });
      }
    }
  };

  const addCompanyToGroup = async companyId => {
    try {
      await CumdAPI.addCompanyToGroup(existingGroupId, {
        companyId: companyId,
      });

      toaster.open({
        message: formatMessage({
          id: "toast.confirmation",
        }),
        description: formatMessage({
          id: "groupDetailsPage.toast.confirmation.text",
        }),
        type: "confirmation",
      });

      setIsCreateGroupModalOpen(false);

      setTimeout(function () {
        window.location.reload();
      }, 800);
    } catch (error) {
      toast.saveError({ error });
    }
  };

  return (
    open && (
      <div className={styles.root} data-test-id="group-modal">
        <div className={styles.titleContainer}>
          <Heading size="s" data-test-id="group-modal-title">
            {mode === GroupModalMode.CREATE_GROUP
              ? formatMessage({ id: "general.createGroup" })
              : formatMessage({ id: "general.addCompanies" })}
          </Heading>

          <Button
            data-test-id="group-modal-close-btn"
            onClick={onClose}
            color="secondary"
            type="tertiary"
            iconName="close"
          />
        </div>

        <div className={styles.contentContainer}>
          <div className={styles.searchContainer}>
            <GroupSearch
              mode={mode}
              sortedCountriesOptions={sortedCountriesOptions}
              companyActivities={companyActivities}
              setGroupName={setGroupName}
              setGroupCode={setGroupCode}
              filterObj={filterObj}
              mergeFilters={mergeFilters}
              initialSearchValue={searchText}
              onSearch={setSearchText}
              onClearFiltersClick={removeAllFiltersAndResetPageNumber}
              groupNameError={groupNameError}
              setGroupNameError={setGroupNameError}
              existingGroupName={existingGroupName}
              existingGroupCode={existingGroupCode}
            />
          </div>
          <div className={styles.bottomContainer}>
            <div className={styles.listContainer}>
              <CompaniesList
                mode={mode}
                companies={companies}
                skipAndTakeQueryStats={skipAndTakeQueryStats}
                paginationQuery={paginationQuery}
                setPaginationQuery={setPaginationQuery}
                companiesInBasket={companiesInBasket}
                setCompaniesInBasket={setCompaniesInBasket}
                countriesOptions={sortedCountriesOptions}
                companyActivities={companyActivities}
                addCompanyToGroup={addCompanyToGroup}
              />
            </div>

            {mode === GroupModalMode.CREATE_GROUP && (
              <div className={styles.basketContainer}>
                <Basket
                  mode={mode}
                  companiesInBasket={companiesInBasket}
                  setCompaniesInBasket={setCompaniesInBasket}
                  createGroup={createGroup}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    )
  );
};

export default GroupModal;
