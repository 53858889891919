import { defineMessages } from "react-intl";

const messages = defineMessages({
  title: {
    id: "shared.business.filesImportModal.title",
    defaultMessage: "Import file",
  },
  subtitle: {
    id: "shared.business.filesImportModal.subtitle",
    defaultMessage: "Import tags on your contacts lists :",
  },
  step1Content: {
    id: "shared.business.filesImportModal.step1Content",
    defaultMessage: "Download the XLSX template to fill.",
  },
  step2Content: {
    id: "shared.business.filesImportModal.step2Content",
    defaultMessage:
      "Once the XLSX template is filled, drag it ino the zone below",
  },
  step3Content: {
    id: "shared.business.filesImportModal.step3Content",
    defaultMessage: "Select a file to import.",
  },
  step4Content: {
    id: "shared.business.filesImportModal.step4Content",
    defaultMessage: "Click on the `import` button.",
  },
  importButton: {
    id: "shared.business.filesImportModal.importButton",
    defaultMessage: "Import",
  },
  downloadButton: {
    id: "shared.business.filesImportModal.downloadButton",
    defaultMessage: "Download",
  },
  importPlaceholder: {
    id: "shared.business.filesImportModal.importPlaceholder",
    defaultMessage: "Drag here or import",
  },
  importFailed: {
    id: "shared.business.filesImportModal.importFailed",
    defaultMessage: "File import failed, an error occurred, please try again.",
  },
});

export default messages;
