import { useIntl } from "react-intl";

import { Typography } from "@trace-one/design-system";

import { GroupData } from "models";

import { formatDate } from "shared/utils/dateUtils";

import { companyGroupDetails } from "../../../../models";

import styles from "./GroupBannerDetails.module.less";

const GroupBannerDetails = ({
  groupDetails,
  companies,
}: {
  groupDetails: GroupData;
  companies: companyGroupDetails[];
}) => {
  const { formatMessage } = useIntl();

  const numberOfActiveCompanies = companies.filter(
    company => company?.isActive
  ).length;

  const mainCompany = groupDetails.companies.find(
    c => c.companyId === groupDetails.mainCompanyId
  );

  return (
    <div className={styles.root}>
      <div className={styles.groupDetailsBanner}>
        <div className={styles.mainDetailLabel}>
          <Typography variant="heading-xxs">
            {formatMessage({
              id: "groupList.table.mainCompany",
            })}
          </Typography>
          <Typography variant="body-regular">
            {mainCompany
              ? companies.find(c => c.companyId === mainCompany.companyId)
                  ?.companyDisplayName
              : "-"}
          </Typography>
        </div>
        <div className={styles.mainDetailLabel}>
          <Typography variant="heading-xxs">
            {formatMessage({
              id: "general.creationDate",
            })}
          </Typography>
          <Typography variant="body-regular">
            {formatDate(groupDetails.createdAt, "DD/MM/YYYY")}
          </Typography>
        </div>
        <div className={styles.mainDetailLabel}>
          <Typography variant="heading-xxs">
            {formatMessage({
              id: "groupList.table.numberOfCompanies",
            })}
          </Typography>
          <Typography variant="body-regular">
            {numberOfActiveCompanies}
          </Typography>
        </div>
        <div className={styles.mainDetailLabel}>
          <Typography variant="heading-xxs">
            {formatMessage({
              id: "groupList.table.groupCode",
            })}
          </Typography>
          <Typography variant="body-regular">
            {groupDetails.groupCode ?? "-"}
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default GroupBannerDetails;
