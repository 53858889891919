import { useIntl } from "react-intl";

import { Company } from "@trace-one/api-clients.cumd/dist/models/company";
import { Button } from "@trace-one/design-system";

import ModalTitle from "../ModalTitle";
import styles from "../RelationCreate.module.less";

import Panel from "./Panel";

interface CompanySelectListProps {
  companies: Company[];
  selectedCompanyId: string;
  setSelectedCompanyId: (selectedCompanyId: string) => void;
  handleSubmit: () => void;
}

const CompanySelectList: React.FC<CompanySelectListProps> = ({
  companies,
  selectedCompanyId,
  setSelectedCompanyId,
  handleSubmit,
}) => {
  const { formatMessage } = useIntl();

  return (
    <>
      <ModalTitle
        content={
          <>
            <h3>
              {formatMessage({
                id: "companyRelationList.modal.companySelect.title",
              })}
              <span>
                {formatMessage(
                  { id: "companyRelationList.companiesFound" },
                  { number: companies.length }
                )}
              </span>
            </h3>
            <p>
              {formatMessage({
                id: "companyRelationList.modal.companySelect.subTitle",
              })}
            </p>
          </>
        }
      />
      <div className={styles.companySelectList}>
        {companies.map(company => {
          const {
            companyId,
            companyDisplayName,
            companyAddress1,
            companyPostalCode,
            companyCity,
            companyCountry,
          } = company;

          return (
            <Panel
              key={companyId}
              title={companyDisplayName}
              subTitle={`${companyAddress1}, ${companyPostalCode}, ${companyCity}, ${companyCountry}`}
              onSelect={() => {
                setSelectedCompanyId(companyId);
              }}
              selected={selectedCompanyId === companyId}
            />
          );
        })}
      </div>
      <div className={styles.buttonContainer}>
        <div className={styles.rightButton}>
          <Button
            type="primary"
            htmlType="submit"
            disabled={selectedCompanyId === null}
            onClick={handleSubmit}
            data-test-id="create-relation-modal-create-button"
          >
            {formatMessage({
              id: "companyRelationList.createRelation",
            })}
          </Button>
        </div>
      </div>
    </>
  );
};

export default CompanySelectList;
