import { useIntl } from "react-intl";

import { ToggleButtons } from "@trace-one/design-system";

import Table from "components/Table";

interface ProductHeaderProps {
  listingResult?: string;
  companyGroupId?: string;
  currentTab?: string;
  setCurrentTab?: (string) => void;
  companyIsPartOfGroup?: boolean;
  hasGroupSubscriptionAccess?: boolean;
  fetchSharedSubscriptions?: () => void;
}

const SubscriptionTableHeader: React.FC<ProductHeaderProps> = ({
  listingResult,
  companyGroupId,
  currentTab,
  setCurrentTab,
  companyIsPartOfGroup,
  hasGroupSubscriptionAccess,
  fetchSharedSubscriptions,
}) => {
  const { formatMessage } = useIntl();

  const tabbedButtons = [
    {
      value: "company-subscriptions",
      name: formatMessage({
        id: "group.companySubscription",
      }),
    },
    ...(hasGroupSubscriptionAccess && companyIsPartOfGroup
      ? [
          {
            value: "group-subscriptions",
            name: formatMessage({
              id: "group.groupSubscription",
            }),
          },
        ]
      : []),
  ];

  return (
    <Table.Header listingResult={listingResult}>
      {companyGroupId !== null && hasGroupSubscriptionAccess && (
        <div>
          <ToggleButtons
            value={currentTab}
            onChange={value => {
              setCurrentTab(value);
              fetchSharedSubscriptions();
            }}
            options={tabbedButtons}
          />
        </div>
      )}
    </Table.Header>
  );
};

export default SubscriptionTableHeader;
