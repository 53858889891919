import { useIntl } from "react-intl";

import { Checkbox } from "@trace-one/react-components";
import { ColumnsType } from "antd/lib/table";

import { PermissionsByRoleColProps } from "../../../../UserAccessManage/models";
import styles from "../../AppsAndPermissionsSection.module.less";

export default function usePermissionsTable(
  sharedRoles,
  subscriptionRolesAndPermissions
) {
  const { formatMessage } = useIntl();

  const columns: ColumnsType<PermissionsByRoleColProps> = [
    {
      title: formatMessage({
        id: "general.permissions",
      }),
      width: 250,
      dataIndex: "name",
      key: "name",
      fixed: "left",
      className: styles.stickyCol,
    },
  ];

  const subscriptionRolesMap = Array.isArray(subscriptionRolesAndPermissions)
    ? subscriptionRolesAndPermissions.reduce((acc, role) => {
        if (role?.roleId) {
          acc[role.roleId] = role;
        }
        return acc;
      }, {})
    : {};

  sharedRoles?.forEach(company => {
    company?.roles
      .filter(role => role?.roleId && subscriptionRolesMap[role?.roleId])
      .forEach(role => {
        columns.push({
          title: role?.name,
          dataIndex: role.roleId,
          key: role.roleId,
          align: "center",
        });
      });
  });

  const data = [];
  const mergedRows = {};

  sharedRoles?.forEach(company => {
    company?.roles
      .filter(role => subscriptionRolesMap[role?.roleId])
      .forEach(role => {
        role?.permissionSets?.forEach(permissionSet => {
          const key = `${permissionSet?.code}-${permissionSet?.name}`;

          if (!mergedRows[key]) {
            mergedRows[key] = {
              name: permissionSet?.name,
              key,
            };
          }

          mergedRows[key][role?.roleId] = (
            <Checkbox
              disabled={!role.isAdmin}
              value={role?.roleId === role.roleId}
            />
          );
        });
      });
  });

  Object.values(mergedRows).forEach(row => {
    data.push(row);
  });

  return { columns, data };
}
