import React from "react";
import { useIntl } from "react-intl";

import { Illustration, Typography } from "@trace-one/design-system";

import styles from "../../AppsAndPermissionsSection.module.less";

const PermissionsNoFound = () => {
  const { formatMessage } = useIntl();

  return (
    <div className={styles.noFound}>
      <Illustration name="no-data" color="grey" />
      <Typography>
        {formatMessage({
          id: "userAccessManage.groupAccess.appsAndPermissions.noFound.text",
        })}
      </Typography>
    </div>
  );
};

export default PermissionsNoFound;
