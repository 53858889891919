import { useState } from "react";

import { Icon } from "@trace-one/design-system";

import RemoveFromGroupModal from "../../../../components/RemoveFromGroupModal";
import usePermissions from "../../../../core/oidc/usePermissions";

import { CompanySharedSubscriptionTableColumn } from "./models";

const SharedSubscriptionAction = ({
  record,
  fetchSharedSubscriptions,
}: {
  record?: CompanySharedSubscriptionTableColumn;
  fetchSharedSubscriptions?: any;
}) => {
  const { hasRoleSuperAdmin, hasRoleAdministrator } = usePermissions();
  const [isRemoveFromGroupModalVisible, setIsRemoveFromGroupModalVisible] =
    useState<boolean>(false);

  return (
    <>
      {(hasRoleSuperAdmin || hasRoleAdministrator) && (
        <Icon
          name="trash"
          color="red"
          style={{ cursor: "pointer" }}
          onClick={() => setIsRemoveFromGroupModalVisible(true)}
        />
      )}

      <RemoveFromGroupModal
        isModalVisible={isRemoveFromGroupModalVisible}
        setIsModalVisible={setIsRemoveFromGroupModalVisible}
        subscriptionId={record?.key}
        associatedCompanyId={record?.associatedCompanyId}
        fetchSharedSubscriptions={fetchSharedSubscriptions}
      />
    </>
  );
};

export default SharedSubscriptionAction;
