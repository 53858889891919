import { useEffect, useState } from "react";
import { useIntl } from "react-intl";

import { SharedSubscription } from "@trace-one/api-clients.cumd/dist/models/subscription";
import { Icon } from "@trace-one/design-system";
import { ColumnsType } from "antd/lib/table";
import moment from "moment";

import AppIcon from "components/AppIcon";

import { CumdAPI } from "../../../../../apis";
import useToast from "../../../../../shared/hooks/useToast";
import { CompanySharedSubscriptionTableColumn } from "../models";
import SharedSubscriptionAction from "../SharedSubscriptionAction";

export default function useCompanySharedSubscriptionTable({
  sharedSubscriptions,
  fetchSharedSubscriptions,
}: {
  companyId: string;
  companyGroupId?: string;
  sharedSubscriptions: SharedSubscription[];
  refetchData: () => void;
  company?: any;
  fetchSharedSubscriptions?: any;
}) {
  const { formatMessage } = useIntl();
  const toast = useToast();

  const [userData, setUserData] = useState<any>();

  const sharedUserIds = sharedSubscriptions?.flatMap(subscription =>
    subscription.sharedCompanies?.map(
      sharedCompany => sharedCompany.sharedByUserId
    )
  );
  const fetchUsers = async () => {
    if (sharedUserIds && sharedUserIds.length > 0) {
      try {
        const { data } = await CumdAPI.getUsersByUserIds({
          userIds: sharedUserIds,
        });

        setUserData(data);
      } catch (error) {
        toast.saveError({ error });
      }
    }
  };

  useEffect(() => {
    fetchUsers();
  }, [sharedSubscriptions]);

  const usersName = userData?.map(user => {
    return `${user?.userName}`;
  });

  const userFullName = usersName?.length === 1 ? usersName[0] : null;

  const sharedColumns: ColumnsType<CompanySharedSubscriptionTableColumn> = [
    {
      title: formatMessage({
        id: "companySubscriptionList.subscriptionId",
      }),
      dataIndex: "key",
      key: "key",
      width: 200,
      ellipsis: true,
    },

    {
      title: formatMessage({
        id: "general.company",
      }),
      dataIndex: "companyName",
      key: "companyName",
      width: 200,
      ellipsis: true,
    },

    {
      title: formatMessage({
        id: "general.shared",
      }),
      dataIndex: "sharedDate",
      key: "sharedDate",
      width: 150,
      ellipsis: true,
    },

    {
      title: formatMessage({
        id: "general.addedBy",
      }),
      dataIndex: "addedBy",
      key: "addedBy",
      width: 200,
      ellipsis: true,
    },

    {
      title: formatMessage({
        id: "general.applications",
      }),
      dataIndex: "applicationTypeId",
      key: "applicationTypeId",
      width: 100,
      render: (applicationTypeId: number, record) => {
        return <AppIcon type={applicationTypeId} />;
      },
    },

    {
      title: <Icon name="settings" />,
      dataIndex: "actions",
      key: "actions",
      width: 40,
      align: "center",
      fixed: "right",
      ellipsis: true,
      render: (_, record) => (
        <SharedSubscriptionAction
          record={record}
          fetchSharedSubscriptions={fetchSharedSubscriptions}
        />
      ),
    },
  ];

  const sharedData = sharedSubscriptions?.flatMap(subscription => {
    return subscription.sharedCompanies.map(sharedCompany => {
      const result: CompanySharedSubscriptionTableColumn = {
        key: subscription.subscriptionId,
        companyName: sharedCompany.associatedCompanyName,
        sharedDate: moment(sharedCompany.sharedDate).format("DD/MM/YYYY"),
        addedBy: userFullName,
        applicationTypeId: subscription.applicationId,
        associatedCompanyId: sharedCompany.associatedCompanyId,
        rawData: subscription,
      };
      return result;
    });
  });

  return { sharedColumns, sharedData };
}
