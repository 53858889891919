import React, { useState } from "react";

import { UserForUIAdmin } from "@trace-one/api-clients.cumd";

import { ReferenceListItemData } from "models";

import BottomPart from "./BottomPart";
import LeftPart from "./LeftPart";
import RightPart from "./RightPart";
import styles from "./UserAccessManage.module.less";
const UserAccessManagePage: React.FC<{
  refetchUser: Function;
  user: UserForUIAdmin;
  teamMemberResponsibilities: ReferenceListItemData[];
  control?: any;
  formState?: any;
  resetFormIdRef?: any;
}> = ({ user, teamMemberResponsibilities, control }) => {
  const [selectedApp, setSelectedApp] = useState<number>(null);

  return (
    <div className={styles.partsWrapper}>
      <div className={styles.top}>
        <LeftPart user={user} />
        <RightPart
          user={user}
          control={control}
          teamMemberResponsibilities={teamMemberResponsibilities}
          selectedApp={selectedApp}
          setSelectedApp={setSelectedApp}
        />
      </div>
      <div className={styles.bottom}>
        <BottomPart
          user={user}
          control={control}
          selectedApp={selectedApp}
          setSelectedApp={setSelectedApp}
        />
      </div>
    </div>
  );
};

export default UserAccessManagePage;
