import React, { useEffect } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";

import { Company } from "@trace-one/api-clients.cumd/dist/models/company";
import { Label, Select, Input, Button } from "@trace-one/react-components";
import { Form } from "antd";

import { CumdAPI } from "apis";
import { CompanyForTraceoneAdminData } from "models";

import { useAppDispatch } from "reduxStore";
import {
  fetchCompanyActivities,
  fetchCountries,
} from "reduxStore/shared/asyncActions";
import {
  selectCompanyActivitiesData,
  selectCountriesData,
} from "reduxStore/shared/selectors";
import { selectUserLanguageCode } from "reduxStore/user/selectors";

import { CompanyStatus, RelationType } from "shared/constants";
import useToast from "shared/hooks/useToast";

import ModalTitle from "../ModalTitle";
import styles from "../RelationCreate.module.less";

interface CompanySearchProps {
  company: CompanyForTraceoneAdminData;
  setRelationType: (type: RelationType) => void;
  setCompanies: (companies: Company[]) => void;
}

const CompanySearch: React.FC<CompanySearchProps> = ({
  company,
  setRelationType,
  setCompanies,
}) => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const relationOptions = [
    {
      name: formatMessage({ id: "companyRelationList.relationType.animator" }),
      value: RelationType.ANIMATOR,
    },
    {
      name: formatMessage({
        id: "companyRelationList.relationType.contributor",
      }),
      value: RelationType.CONTRIBUTOR,
    },
  ];

  const toast = useToast();

  const languageCode = useSelector(selectUserLanguageCode);

  const companyActivities = useSelector(selectCompanyActivitiesData);
  const companyActivityOptions = companyActivities.data.map(activity => ({
    name: activity.text,
    value: activity.itemCode,
  }));

  const countries = useSelector(selectCountriesData);
  const countryOptions = countries.data.map(country => ({
    name: country.name,
    value: country.code,
  }));

  const sortedCountriesOptions = countryOptions?.sort((a, b) => {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  });

  const handleSubmit = async values => {
    setRelationType(values.relationType);

    try {
      const { data } = await CumdAPI.getCompaniesByFiltersForToAdmin(
        {
          companyStatuses: [CompanyStatus.ENABLED],
        },
        {
          searchText: values.companyName,
          companyActivityId: values.companyActivity,
          companyCountry: values.companyCountry,
        }
      );

      const filteredCompanies = data.companies.filter(
        item => item.companyId !== company.companyId
      );

      setCompanies(filteredCompanies);
    } catch (error) {
      toast.saveError({ error });
    }
  };

  useEffect(() => {
    dispatch(
      fetchCompanyActivities({
        languageCode,
      })
    );
    dispatch(
      fetchCountries({
        languageCode,
      })
    );
  }, []);

  const [form] = Form.useForm();

  return (
    <>
      <div>
        <Form name="basic" form={form} onFinish={handleSubmit}>
          <ModalTitle
            content={
              <h3>
                {formatMessage({
                  id: "companyRelationList.modal.relationType.title",
                })}
              </h3>
            }
          />

          <Label
            title={formatMessage({ id: "companyRelationList.relationType" })}
            required
            mode="horizontal"
          >
            <Form.Item
              name="relationType"
              rules={[
                {
                  required: true,
                  message: formatMessage({
                    id: "companyRelationList.modal.relationType.message",
                  }),
                },
              ]}
            >
              <Select
                showSearch
                placeholder={formatMessage({
                  id: "companyRelationList.relationType",
                })}
                options={relationOptions}
              />
            </Form.Item>
          </Label>

          <ModalTitle
            content={
              <>
                <h3>
                  {formatMessage({
                    id: "companyRelationList.modal.companySearch.title",
                  })}
                </h3>
                <p>
                  {formatMessage({
                    id: "companyRelationList.modal.companySearch.subTitle",
                  })}
                </p>
              </>
            }
          />
          <Label
            title={formatMessage({
              id: "companyRelationList.companyActivity",
            })}
            mode="horizontal"
          >
            <Form.Item name="companyActivity">
              <Select
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                placeholder={formatMessage({
                  id: "general.selectValue",
                })}
                options={companyActivityOptions}
                data-test-id="company-activity-select"
              />
            </Form.Item>
          </Label>

          <Label
            title={formatMessage({
              id: "general.companyName",
            })}
            mode="horizontal"
            required
          >
            <Form.Item
              name="companyName"
              rules={[
                {
                  required: true,
                  message: formatMessage({
                    id: "companyRelationList.modal.companySearch.companyName.message",
                  }),
                },
              ]}
            >
              <Input
                placeholder={formatMessage({
                  id: "general.enterCompanyName",
                })}
              />
            </Form.Item>
          </Label>

          <Label
            title={formatMessage({
              id: "general.country",
            })}
            mode="horizontal"
          >
            <Form.Item name="companyCountry">
              <Select
                allowClear
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                placeholder={formatMessage({
                  id: "general.selectValue",
                })}
                options={sortedCountriesOptions}
              />
            </Form.Item>
          </Label>

          <div className={styles.buttonContainer}>
            <div className={styles.leftButton}>
              <Button
                type="primary"
                htmlType="submit"
                data-test-id="create-relation-modal-search-button"
              >
                {formatMessage({
                  id: "general.search",
                })}
              </Button>
            </div>
          </div>
        </Form>
      </div>
    </>
  );
};

export default CompanySearch;
