import React, { Dispatch, SetStateAction, useState } from "react";
import { Control } from "react-hook-form";
import { useIntl } from "react-intl";

import { Toggle, Button, Tooltip } from "@trace-one/design-system";
import { Col } from "antd";

import Card from "components/Card";

import FormController from "../../../../../components/FormController";
import usePermissions from "../../../../../core/oidc/usePermissions";
import {
  ReferenceListItemData,
  UserForUIAdminData,
} from "../../../../../models";
import ResponsibilitiesSelect from "../BottomPart/components/ResponsibilitiesSelect";
import { UserAccessManageValues } from "../models";

import EditUserLogin from "./components/EditUserLogin";
import styles from "./RightPart.module.less";

interface RightPartProps {
  user: UserForUIAdminData;
  selectedApp: number;
  control: Control<UserAccessManageValues>;
  teamMemberResponsibilities: ReferenceListItemData[];
  setSelectedApp: Dispatch<SetStateAction<number>>;
}

const RightPart: React.FC<RightPartProps> = ({
  user,
  control,
  teamMemberResponsibilities,
}) => {
  const { formatMessage } = useIntl();
  const { hasRoleSuperAdmin, isTraceoneAdmin } = usePermissions();
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

  const handleChangePassword = () => {
    if (window.env.IDSV_URL && user.userId) {
      const url = `${window.env.IDSV_URL}/user/password/admin/${user.userId}`;
      window.open(url, "_blank");
    }
  };
  const openModal = () => {
    setIsModalVisible(true);
  };

  const limit = 34;
  const shouldTruncate = user?.userLogin?.length > limit;
  const userLoginToDisplay = shouldTruncate
    ? `${user?.userLogin.substring(0, limit)}...`
    : user?.userLogin;

  return (
    <div className={styles.root}>
      <Card
        title={formatMessage({
          id: "general.loginInformation",
        }).toUpperCase()}
      >
        <Col
          className={styles.info}
          style={{ padding: "0" }}
          data-test-id="user-manage-change-login"
        >
          <span className={styles.infoTitle}>
            {formatMessage({ id: "general.loginAddress" })}
          </span>
          <div className={styles.loginWrapper}>
            <Tooltip text={user?.userLogin}>
              <div>{userLoginToDisplay}</div>
            </Tooltip>
            <Button
              type="link"
              iconName="edit"
              onClick={openModal}
              data-test-id="user-manage-change-login-button"
            >
              {formatMessage({
                id: "general.changeLogin",
              })}
            </Button>
            <EditUserLogin
              user={user}
              isModalVisible={isModalVisible}
              setIsModalVisible={setIsModalVisible}
            />
          </div>
        </Col>

        {isTraceoneAdmin && (
          <Col className={styles.info} style={{ padding: "0" }}>
            <span className={styles.infoTitle}>
              {formatMessage({ id: "general.password" })}
            </span>
            <Button
              type="link"
              iconName="edit"
              onClick={handleChangePassword}
              data-test-id="user-manage-change-password-button"
            >
              {formatMessage({ id: "general.changePassword" })}
            </Button>
          </Col>
        )}
      </Card>
      <Card
        title={formatMessage({
          id:
            user.isTraceOneCompany === true
              ? "general.administration"
              : "userAccessManage.responsibilitiesAndAdminCard.title",
        })}
      >
        {user.isTraceOneCompany !== true && (
          <>
            <div className={styles.description}>
              {formatMessage({
                id: "userAccessManage.responsibilitiesAndAdminCard.description",
              })}
            </div>

            <div className={styles.responsibilities}>
              <FormController
                name="responsibilities"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <ResponsibilitiesSelect
                    onChange={onChange}
                    value={value}
                    teamMemberResponsibilities={teamMemberResponsibilities}
                    error={!!error}
                    errorMessage={error?.message}
                    allowClear
                  />
                )}
              />
            </div>
          </>
        )}

        <div className={styles.administration}>
          <FormController
            name="isAccountAdministrator"
            control={control}
            label={
              <div>
                {formatMessage(
                  {
                    id: "userAccessManage.isAccountAdministrator",
                  },
                  {
                    b: text => <b className={styles.star}>{text}</b>,
                  }
                )}
              </div>
            }
            render={({ field: { onChange, value } }) => (
              <Toggle
                size="small"
                checked={value}
                onChange={onChange}
                disabled={
                  hasRoleSuperAdmin === false && user.isTraceOneCompany === true
                }
              />
            )}
          />
        </div>
      </Card>
    </div>
  );
};

export default RightPart;
