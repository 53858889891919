import { useEffect, useRef } from "react";
import { useIntl } from "react-intl";
import { Prompt } from "react-router-dom";

import PropTypes from "prop-types";

export interface LeavingFormPromptProps {
  when: boolean;
}

const LeavingFormPrompt: React.FC<LeavingFormPromptProps> = ({ when }) => {
  const { formatMessage } = useIntl();
  const message = formatMessage({ id: "general.leavingPrompt" });

  const ref = useRef(when);

  useEffect(() => {
    ref.current = when;
  }, [when]);

  useEffect(() => {
    const handleBeforeUnload = e => {
      if (ref.current) {
        e.returnValue = message;
        return message;
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  return <Prompt when={when} message={message} />;
};

LeavingFormPrompt.propTypes = {
  when: PropTypes.bool,
};
export default LeavingFormPrompt;
