import React, { useState } from "react";
import { Modal, Heading, Button, UploadFiles } from "@trace-one/design-system";
import { withLanguageData } from "IndexComponents";
import { useModalVisibility } from "src/hooks";
import { FormattedMessage, useIntl } from "react-intl";
import messages from "./translations/messages";
import { LangList } from "../../../utils/const";
import { FilesImportModalProps } from "./types";
import { getPrefixClassName, isListEmpty } from "src/utils/general";
import "./styles.less";

import enUs from "./translations/en-US.json";
import frFr from "./translations/fr-FR.json";
import deDe from "./translations/de-DE.json";
import nlBe from "./translations/nl-BE.json";
import esEs from "./translations/es-ES.json";
import itIt from "./translations/it-IT.json";
import ptPt from "./translations/pt-PT.json";
import IntructionItem from "./components/IntructionItemProps";

const prefixClass = getPrefixClassName("files-import-modal");

const enhance = withLanguageData({
  translations: {
    [LangList.EN_US]: enUs,
    [LangList.FR_FR]: frFr,
    [LangList.DE_DE]: deDe,
    [LangList.NL_BE]: nlBe,
    [LangList.ES_ES]: esEs,
    [LangList.IT_IT]: itIt,
    [LangList.PT_PT]: ptPt,
  },
});

export const FilesImportModal: React.FC<FilesImportModalProps> = ({
  title,
  subTitle,
  instruction1,
  instruction2,
  instruction3,
  instruction4,
  fileExtensions,
  fileTypeErrorMessage,
  importErrorMessage,
  primaryButtonText,
  downloadButtonText,
  onClose,
  open,
  onDownloadTemplate = () => {},
  onFileSelection = () => {},
  onImportConfirmation = () => {},
  primaryButtonProps,
  downloadButtonProps,
}) => {
  const { formatMessage } = useIntl();
  const [file, setFile] = useState<File | null>(null);
  const [hasImportError, setHasImportError] = useState<boolean>(false);
  const { onCloseModal, isModalOpen } = useModalVisibility({
    defaultVisibility: open,
    onClose,
  });

  const [fileTypeError, setFileTypeError] = useState<boolean>(false);

  const handleFileSelection = (file: File) => {
    const uploadedFileType = file?.type?.split("/")[1];

    if (
      !isListEmpty(fileExtensions) &&
      !fileExtensions?.includes(uploadedFileType)
    ) {
      setFileTypeError(true);
      return;
    }

    setFileTypeError(false);
    onFileSelection(file);
    setFile(file);
  };

  const onClearFile = () => {
    setFile(null);
    setFileTypeError(false);
  };

  let errorMessageText = "";

  if (hasImportError) {
    errorMessageText =
      importErrorMessage || formatMessage(messages.importFailed);
  } else if (fileTypeError) {
    errorMessageText = fileTypeErrorMessage;
  }

  const uploadProps = {
    dragAreaPlaceholdertext: formatMessage(messages.importPlaceholder),
    errorMessage: errorMessageText,
    disabled: false,
    uploadStatus: null,
    onFileSelection: handleFileSelection,
  };

  const importButtonProps = {
    ...primaryButtonProps,
    disabled: !file,
  };

  const handleImportConfirmation = async () => {
    try {
      await onImportConfirmation(file);
      setFile(null);
      setHasImportError(false);
    } catch {
      setHasImportError(true);
    }
  };

  return (
    <Modal.Simple
      size="m"
      className={prefixClass}
      title={title || <FormattedMessage {...messages.title} />}
      open={isModalOpen}
      onCancel={onCloseModal}
      primaryButtonText={
        primaryButtonText || formatMessage(messages.importButton)
      }
      onPrimaryButtonClick={handleImportConfirmation}
      primaryButtonProps={importButtonProps}
    >
      <div className={`${prefixClass}__body`}>
        <Heading size="xs">
          {subTitle || formatMessage(messages.subtitle)}
        </Heading>
        <IntructionItem
          itemNumber={1}
          content={instruction1 || formatMessage(messages.step1Content)}
        >
          <Button
            data-test-id="bs-downlaod-template-button"
            type="tertiary"
            onClick={onDownloadTemplate}
            iconName="download-computer"
            {...downloadButtonProps}
          >
            {downloadButtonText || formatMessage(messages.downloadButton)}
          </Button>
        </IntructionItem>

        <IntructionItem
          itemNumber={2}
          content={instruction2 || formatMessage(messages.step2Content)}
        ></IntructionItem>

        <IntructionItem
          itemNumber={3}
          content={instruction3 || formatMessage(messages.step3Content)}
        >
          <UploadFiles
            data-test-id="bs-import-files-modal-file-selection"
            size="small"
            onClear={onClearFile}
            {...uploadProps}
          ></UploadFiles>
        </IntructionItem>

        <IntructionItem
          itemNumber={4}
          content={instruction4 || formatMessage(messages.step4Content)}
        ></IntructionItem>
      </div>
    </Modal.Simple>
  );
};

export default enhance(FilesImportModal);
