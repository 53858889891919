import { useEffect, useState } from "react";
import { useIntl } from "react-intl";

import { Icon } from "@trace-one/design-system";
import { ColumnsType } from "antd/lib/table";
import moment from "moment";

import AppIcon from "components/AppIcon";

import { CumdAPI } from "../../../../../apis";
import useToast from "../../../../../shared/hooks/useToast";
import { SharedGroupSubscriptionTableColumn } from "../../../models";
import SubscriptionAction from "../SharedSubscriptions/SubscriptionAction";

export default function useSharedSubscriptionsTable({
  allSharedSubscriptions,
  groupAllDetails,
  groupId,
  fetchAllGroupDetails,
}: {
  allSharedSubscriptions?: any;
  groupAllDetails?: any;
  groupId?: string;
  fetchAllGroupDetails?: any;
}) {
  const { formatMessage } = useIntl();
  const toast = useToast();

  const [userData, setUserData] = useState<any>();

  const sharedUserIds = allSharedSubscriptions?.flatMap(subscription =>
    subscription.sharedCompanies?.map(
      sharedCompany => sharedCompany?.sharedByUserId
    )
  );

  const fetchUsers = async () => {
    if (sharedUserIds && sharedUserIds.length > 0) {
      try {
        const { data } = await CumdAPI.getUsersByUserIds({
          userIds: sharedUserIds,
        });

        setUserData(data);
      } catch (error) {
        toast.saveError({ error });
      }
    }
  };

  useEffect(() => {
    fetchUsers();
  }, [sharedUserIds.length]);

  const usersName = userData?.map(user => {
    return `${user?.userName}`;
  });

  const userFullName = usersName?.length === 1 ? usersName[0] : null;

  const columns: ColumnsType<SharedGroupSubscriptionTableColumn> = [
    {
      title: formatMessage({
        id: "companySubscriptionList.subscriptionId",
      }),
      dataIndex: "key",
      key: "key",
      width: 100,
      ellipsis: true,
    },

    {
      title: formatMessage({
        id: "general.ownerCompany",
      }),
      dataIndex: "companyName",
      key: "companyName",
      width: 150,
      ellipsis: true,
    },

    {
      title: formatMessage({ id: "general.sharedWith" }),
      dataIndex: "associatedCompanyDisplayName",
      key: "associatedCompanyDisplayName",
      width: 150,
      ellipsis: true,
    },

    {
      title: formatMessage({
        id: "general.shared",
      }),
      dataIndex: "sharedDate",
      key: "sharedDate",
      width: 100,
      ellipsis: true,
    },

    {
      title: formatMessage({
        id: "general.addedBy",
      }),
      dataIndex: "addedBy",
      key: "addedBy",
      width: 100,
      ellipsis: true,
    },

    {
      title: formatMessage({
        id: "general.applications",
      }),
      dataIndex: "applicationTypeId",
      key: "applicationTypeId",
      width: 100,
      render: (applicationTypeId: number, record) => {
        return <AppIcon type={applicationTypeId} />;
      },
    },

    {
      title: <Icon name="settings" />,
      dataIndex: "actions",
      key: "actions",
      width: 40,
      align: "center",
      fixed: "right",
      ellipsis: true,
      render: (_, record) => (
        <SubscriptionAction
          record={record}
          fetchAllGroupDetails={fetchAllGroupDetails}
          groupId={groupId}
        />
      ),
    },
  ];

  const data = groupAllDetails?.companies?.flatMap(company => {
    return company?.sharedSubscriptions?.flatMap(subscription => {
      return subscription?.sharedCompanies.map(sharedCompany => {
        const result: SharedGroupSubscriptionTableColumn = {
          key: subscription.functionalId,
          subscriptionId: subscription.subscriptionId,
          companyName: company?.companyDisplayName,
          associatedCompanyDisplayName:
            sharedCompany.associatedCompanyDisplayName,
          associatedCompanyId: sharedCompany.associatedCompanyId,
          sharedDate: moment(sharedCompany.sharedDate).format("DD/MM/YYYY"),
          addedBy: userFullName,
          applicationTypeId: subscription.applicationTypeId,
          rawData: subscription,
        };
        return result;
      });
    });
  });

  return { columns, data };
}
