import { useIntl } from "react-intl";

import { GroupDetails } from "@trace-one/api-clients.cumd/dist/models/group";
import { Typography } from "@trace-one/design-system";

import { formatDate } from "shared/utils/dateUtils";

import styles from "./SharedSubscriptionBanner.module.less";

const SharedSubscriptionBanner = ({
  groupDetails,
  numberOfActiveCompanies,
}: {
  groupDetails: GroupDetails;
  numberOfActiveCompanies?: number;
}) => {
  const { formatMessage } = useIntl();

  const mainCompanyId = groupDetails?.mainCompanyId;

  const mainCompanyName = groupDetails?.companies?.find(
    company => company?.companyId === groupDetails?.mainCompanyId
  )?.companyDisplayName;

  return (
    <div className={styles.root}>
      <div className={styles.groupDetailsBanner}>
        <div className={styles.mainDetailLabel}>
          <Typography variant="heading-xxs">
            {formatMessage({
              id: "groupList.table.mainCompany",
            })}
          </Typography>
          <Typography variant="body-regular">
            {mainCompanyId && mainCompanyName}
          </Typography>
        </div>
        <div className={styles.mainDetailLabel}>
          <Typography variant="heading-xxs">
            {formatMessage({
              id: "general.creationDate",
            })}
          </Typography>
          <Typography variant="body-regular">
            {groupDetails?.createdAt &&
              formatDate(groupDetails?.createdAt, "DD/MM/YYYY")}
          </Typography>
        </div>
        <div className={styles.mainDetailLabel}>
          <Typography variant="heading-xxs">
            {formatMessage({
              id: "groupList.table.numberOfCompanies",
            })}
          </Typography>
          <Typography variant="body-regular">
            {numberOfActiveCompanies}
          </Typography>
        </div>
        <div className={styles.mainDetailLabel}>
          <Typography variant="heading-xxs">
            {formatMessage({
              id: "groupList.table.groupCode",
            })}
          </Typography>
          <Typography variant="body-regular">
            {groupDetails?.groupCode}
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default SharedSubscriptionBanner;
